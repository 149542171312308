// ** Icons Imports
import {
  CloseRounded,
  InfoTwoTone,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  MoodRounded,
  SensorsRounded,
  SentimentDissatisfiedRounded,
  SentimentNeutralRounded,
  SentimentSatisfiedAltRounded,
  TimerTwoTone,
  TouchAppRounded,
} from "@mui/icons-material";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// ** MUI Imports
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import DebugModeInfo from "./DebugModeInfo";
import { Line, Machines, MachineType } from "../message";
import { generateTime } from "./LineDownInfoDialog";

// ** Types
type ProductivityInfoDialogProps = {
  productivityInfoData: Line;
  machines: Machines;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

const ProductivityInfoDialog = (props: ProductivityInfoDialogProps) => {
  // ** Props
  const { productivityInfoData, machines, dialogState, setDialogState } = props;

  // ** States
  const [expandedPaused, setExpandedPaused] = useState<boolean>(false);
  const [expandedPlannedPaused, setExpandedPlannedPaused] =
    useState<boolean>(false);
  const [expandedUnplannedPaused, setExpandedUnplannedPaused] =
    useState<boolean>(false);
  const [expandedErrorsFromSensor, setExpandedErrorsFromSensor] =
    useState<boolean>(false);
  const [expandedErrorsFromOperator, setExpandedErrorsFromOperator] =
    useState<boolean>(false);

  const [productivityColor, setProductivityValue] =
    useState<string>("2, 224, 164");
  const [productivityEmoji, setProductivityEmoji] = useState<any>();

  const returnColor = () => {
    if (productivityInfoData.OEE >= 85) {
      return "2, 224, 164";
    }

    if (productivityInfoData.OEE >= 60) {
      return "131, 217, 20";
    }

    if (productivityInfoData.OEE >= 40) {
      return "243, 156, 18";
    }

    return "255, 76, 81";
  };

  const returnErrorColor = (machineType: MachineType | undefined) => {
    if (machineType === "GENERAL") {
      //return "#F48FB1";
      return "244, 143, 177";
    } else if (machineType === "BESLEME") {
      //return "#80CBC4";
      return "128, 203, 196";
    } else if (machineType === "DOLUM") {
      //return "#FFAB91";
      return "255, 171, 145";
    } else if (machineType === "ETIKET") {
      //return "#81D4FA";
      return "129, 212, 250";
    } else if (machineType === "KUTULAMA") {
      //return "#B39DDB";
      return "179, 157, 219";
    } else if (machineType === "ITS") {
      //return "#A5D6A7";
      return "165, 214, 167";
    } else if (machineType === "KOLILEME") {
      //return "#e85454";
      return "232, 84, 84";
    }

    //return "#f0f3f4";
    return "240, 243, 244";
  };

  const returnEmoji = () => {
    if (productivityInfoData.OEE >= 85) {
      return (
        <MoodRounded
          sx={{
            fontSize: 52,
          }}
        />
      );
    }

    if (productivityInfoData.OEE >= 60) {
      return <SentimentSatisfiedAltRounded sx={{ fontSize: 52 }} />;
    }

    if (productivityInfoData.OEE >= 40) {
      return (
        <SentimentNeutralRounded
          sx={{
            fontSize: 52,
          }}
        />
      );
    }

    return (
      <SentimentDissatisfiedRounded
        sx={{
          fontSize: 52,
        }}
      />
    );
  };

  useEffect(() => {
    setProductivityValue(returnColor());
    setProductivityEmoji(returnEmoji());
  }, [productivityInfoData]);

  // ** Functions
  const onClose = () => {
    setDialogState(false);
  };

  const sensorsErrorSum = () => {
    let sum = 0;

    machines.forEach((d) => {
      d.Sensors.forEach((item) => {
        if (item.sensor_type === "ERROR" && item.time) {
          sum = sum + Number(item.time);
        }
      });
    });

    return (sum / 60).toFixed(0);
  };

  const operatorsErrorSum = () => {
    let sum = 0;

    machines.forEach((d) => {
      d.ErrorsFromOperator?.forEach((item) => {
        sum = sum + Number(item.duration);
      });
    });

    return (sum / 60).toFixed(0);
  };

  useEffect(() => {
    setProductivityValue(returnColor());
    setProductivityEmoji(returnEmoji());
  }, [productivityInfoData]);

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="md"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          background: "rgba(255, 255, 255, 0.13) !important",
          backdropFilter: "blur(16px)",
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.75)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <TimerTwoTone
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
              fontWeight: 600,
            }}
          >
            OEE Detaylı Analiz
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="small"
            aria-label="settings"
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3.5, px: { xs: 1, md: 10 } }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              background: "rgb(33, 36, 45)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
              gap: 2.5,
            }}
          >
            <InfoTwoTone
              sx={{
                fontSize: "1.75rem",
                color: "#d0d3d4",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontStyle: "italic",
                  color: "#f0f3f4",
                }}
              >
                OEE (Verim) hesabının anlık hesaplanabilmesi için planlanan
                üretim süresi yerine aktif çalışma süresi kullanılması gerekir.
                Vardiya bitiminde ise yeniden planlanan üretim süresi baz
                alınarak verim hesabı yapılır ve kaydedilir.
              </Typography>
            </Box>
          </Box>

{/* */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              background: "rgb(33, 36, 45)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                px: 3,
                py: 2,
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <DebugModeInfo
                        label="LineAvailability"
                        top={0}
                        left={0}
                      />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "rgba(255,255,255,0.72)",
                        }}
                      >
                        Kullanılabilirlik (Availability)
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: 1,
                          background: "#ffa233",
                        }}
                      ></Box>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          color: "rgba(255,255,255,0.87)",
                        }}
                      >
                        {productivityInfoData.Availability &&
                          productivityInfoData.Availability.toFixed(1)}{" "}
                        %
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      borderRadius: 1,
                      background: "rgba(255, 139, 0, 0.13)",
                      height: 12,
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        background: "rgb(255, 139, 0)",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: `calc(100% - ${productivityInfoData.Availability}%)`,
                        borderRadius: 1,
                      }}
                    ></Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <DebugModeInfo label="LinePerformance" top={0} left={0} />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "rgba(255,255,255,0.72)",
                        }}
                      >
                        Performans (Performance)
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: 1,
                          background: "#fdd566",
                        }}
                      ></Box>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "rgba(255,255,255,0.87)",
                        }}
                      >
                        {productivityInfoData.Performance &&
                          productivityInfoData.Performance.toFixed(1)}{" "}
                        %
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      borderRadius: 1,
                      background: "rgba(253, 202, 64, 0.13)",
                      height: 12,
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        background: "rgb(253, 202, 64)",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: `calc(100% - ${productivityInfoData.Performance}%)`,
                        borderRadius: 1,
                      }}
                    ></Box>
                  </Box>
                </Box>
                {/** 
                   * 
                   *                 <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <DebugModeInfo label="LineQuality" top={0} left={0} />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "rgb(255,255,255,0.72)",
                        }}
                      >
                        Kalite (Quality)
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: 1,
                          background: "#508aff",
                        }}
                      ></Box>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "rgb(255,255,255,0.87)",
                        }}
                      >
                        {productivityInfoData.Quality &&
                          productivityInfoData.Quality.toFixed(1)}{" "}
                        %
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      borderRadius: 1,
                      background: "rgba(36, 109, 255, 0.12)",
                      height: 12,
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        background: "rgb(36, 109, 255)",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: `calc(100% - ${productivityInfoData.Quality}%)`,
                        borderRadius: 1,
                      }}
                    ></Box>
                  </Box>
                </Box>
                  */}
              </Box>
              {/**
                 *               <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <DebugModeInfo position="absolute" label="LineOEE" top={78} />
                <Box
                  sx={{
                    position: "absolute",
                    top: 24,
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    color: `rgb(${productivityColor})`,
                  }}
                >
                  {productivityEmoji}
                </Box>
                <Box
                  sx={{
                    width: 96,
                    height: 96,
                  }}
                >
                  <CircularProgressbar
                    value={productivityInfoData.OEE}
                    strokeWidth={9}
                    styles={buildStyles({
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Colors
                      pathColor: `rgba(${productivityColor}, ${90 / 100})`,
                      trailColor: `rgba(${productivityColor}, 0.13)`,
                    })}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    color: "rgb(255,255,255,0.87)",
                  }}
                >
                  {productivityInfoData.OEE &&
                    productivityInfoData.OEE.toFixed(1)}{" "}
                  %
                </Typography>
              </Box>
                 */}
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label="LineShiftLength" top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  Vardiya Uzunluğu
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {productivityInfoData.ShiftLength || 0} dk
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: expandedPaused
                  ? "1px solid rgba(255,255,255, 0.13)"
                  : "none",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label="LineBreaks" top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Molalar
                  </Typography>
                </Box>
                <Tooltip title={expandedPaused ? "Daralt" : "Genişlet"}>
                  <IconButton
                    sx={{
                      borderRadius: 1,
                      width: 24,
                      height: 24,
                      background: "rgba(255,255,255,0.13)",
                      border: "1px solid rgba(255,255,255,0.24)",
                    }}
                    onClick={() => {
                      setExpandedPaused(!expandedPaused);
                    }}
                  >
                    {expandedPaused ? (
                      <KeyboardArrowUpRounded
                        sx={{
                          color: "#f0f3f4",
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownRounded
                        sx={{
                          color: "#f0f3f4",
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {productivityInfoData.Breaks} dk
                </Typography>
              </Box>
            </Box>
            {expandedPaused && (
              <>
                <Box
                  sx={{
                    width: "calc(100% - 24px)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(255,255,255, 0.13)",
                    py: 0.5,
                    ml: 3,
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <DebugModeInfo label="LineBreaks1" top={0} left={0} />
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                      }}
                    >
                      Çay ve Yemek
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    {productivityInfoData.Breaks1} dk
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "calc(100% - 24px)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    py: 0.5,
                    ml: 3,
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <DebugModeInfo label="LineBreaks2" top={0} left={0} />
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                      }}
                    >
                      Diğer
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    {productivityInfoData.Breaks2} dk
                  </Typography>
                </Box>
              </>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo
                  label="LineVerifiedBottleTotalPieces"
                  top={0}
                  left={0}
                />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  Toplam Üretilen Adet
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {productivityInfoData.VerifiedBottleTotalPieces} adet
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label="LineRejectTotalPieces" top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  Toplam Reddedilen Adet
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {productivityInfoData.RejectTotalPieces} adet
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label="LineIdealRunRatePPM" top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  Hat Hızı (İdeal Çalışma Oranı)
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {productivityInfoData.IdealRunRatePPM} adet/dk
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: expandedPlannedPaused
                  ? "1px solid rgba(255,255,255, 0.13)"
                  : "none",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label="LinePlannedStops" top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Planlanmış Duruşlar
                  </Typography>
                </Box>
                <Tooltip title={expandedPlannedPaused ? "Daralt" : "Genişlet"}>
                  <IconButton
                    sx={{
                      borderRadius: 1,
                      width: 24,
                      height: 24,
                      background: "rgba(255,255,255,0.13)",
                      border: "1px solid rgba(255,255,255,0.24)",
                    }}
                    onClick={() =>
                      setExpandedPlannedPaused(!expandedPlannedPaused)
                    }
                  >
                    {expandedPlannedPaused ? (
                      <KeyboardArrowUpRounded
                        sx={{
                          color: "#f0f3f4",
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownRounded
                        sx={{
                          color: "#f0f3f4",
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {productivityInfoData.PlannedStops} dk
                </Typography>
              </Box>
            </Box>
            {expandedPlannedPaused && (
              <>
                <Box
                  sx={{
                    width: "calc(100% - 24px)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    py: 0.5,
                    ml: 3,
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <DebugModeInfo label="LinePlannedStops1" top={0} left={0} />
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                      }}
                    >
                      Temizlik ve Ayar
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      pr: 0.5,
                      color: "#f0f3f4",
                    }}
                  >
                    {productivityInfoData.PlannedStops1} dk
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "calc(100% - 24px)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    py: 0.5,
                    ml: 3,
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <DebugModeInfo label="LinePlannedStops2" top={0} left={0} />
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                      }}
                    >
                      Diğer
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      pr: 0.5,
                      color: "#f0f3f4",
                    }}
                  >
                    {productivityInfoData.PlannedStops2} dk
                  </Typography>
                </Box>
              </>
            )}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                //borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label="LineUnplannedStops" top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Planlanmamış Duruşlar
                  </Typography>
                </Box>
                <Tooltip
                  title={expandedUnplannedPaused ? "Daralt" : "Genişlet"}
                >
                  <IconButton
                    sx={{
                      borderRadius: 1,
                      width: 24,
                      height: 24,
                      background: "rgba(255,255,255,0.13)",
                      border: "1px solid rgba(255,255,255,0.24)",
                    }}
                    onClick={() =>
                      setExpandedUnplannedPaused(!expandedUnplannedPaused)
                    }
                  >
                    {expandedUnplannedPaused ? (
                      <KeyboardArrowUpRounded
                        sx={{
                          color: "#f0f3f4",
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownRounded
                        sx={{
                          color: "#f0f3f4",
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {productivityInfoData.UnplannedStops &&
                    productivityInfoData.UnplannedStops.toFixed(0)}{" "}
                  dk
                </Typography>
              </Box>
            </Box>

            {/*
            
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: expandedErrorsFromSensor
                  ? "1px solid rgba(255,255,255, 0.13)"
                  : "none",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label="LinePlannedStops1" top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Sensörden Gelen Duruşlar
                  </Typography>
                </Box>
                <Tooltip
                  title={expandedErrorsFromSensor ? "Daralt" : "Genişlet"}
                >
                  <IconButton
                    sx={{
                      borderRadius: 1,
                      width: 24,
                      height: 24,
                      background: "rgba(255,255,255,0.13)",
                      border: "1px solid rgba(255,255,255,0.24)",
                    }}
                    onClick={() =>
                      setExpandedErrorsFromSensor(!expandedErrorsFromSensor)
                    }
                  >
                    {expandedErrorsFromSensor ? (
                      <KeyboardArrowUpRounded
                        sx={{
                          color: "#f0f3f4",
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownRounded
                        sx={{
                          color: "#f0f3f4",
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography
                sx={{
                  pr: 0.5,
                  color: "#f0f3f4",
                }}
              >
                {sensorsErrorSum()} dk
              </Typography>
            </Box> */}
            {expandedUnplannedPaused &&
              machines.map((item) => (
                <>
                  {item.Sensors.map((subItem, subIndex) => (
                    <>
                      {subItem.sensor_type === "ERROR" && (
                        <Box
                          key={subIndex}
                          sx={{
                            width: "calc(100% - 36px)",
                            display: "flex",
                            justifyContent: {
                              xs: "center",
                              sm: "space-between",
                            },
                            alignItems: "center",
                            flexWrap: "wrap",
                            borderBottom: "1px solid rgba(255,255,255, 0.13)",
                            py: 0.5,
                            ml: 4,
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              flex: 0.66,
                              minWidth: { xs: "100%", sm: "200px" },
                              display: "flex",
                              justifyContent: {
                                xs: "center",
                                sm: "flex-start",
                              },
                              gap: 1.5,
                            }}
                          >
                            <Typography
                              sx={{
                                color: `rgb(${returnErrorColor(item.machine_type)})`,
                              }}
                            >
                              {subItem.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              flex: 0.18,
                              minWidth: { xs: "100%", sm: "128px" },
                              display: "flex",
                              justifyContent: {
                                xs: "center",
                                sm: "flex-start",
                              },
                              alignItems: "center",
                              color: `rgb(${returnErrorColor(item.machine_type)})`,
                              border: `1px dashed rgba(${returnErrorColor(item.machine_type)}, 0.81)`,
                              background: `rgba(${returnErrorColor(item.machine_type)}, 0.21)`,
                              borderRadius: 1,
                              px: 1,
                              py: 0.5,
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <SensorsRounded
                                sx={{
                                  fontSize: "1.1rem",
                                  color: `rgb(${returnErrorColor(item.machine_type)})`,
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Typography>{item.machine_type}</Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              flex: 0.16,
                              minWidth: { xs: "100%", sm: "100px" },
                              display: "flex",
                              gap: 1,
                              justifyContent: { xs: "center", sm: "flex-end" },
                            }}
                          >
                            <Typography
                              sx={{
                                color: `rgb(${returnErrorColor(item.machine_type)})`,
                                mr: 0.5,
                              }}
                            >
                              {generateTime(subItem.time)}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  ))}
                </>
              ))}
            {/*
              
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: expandedErrorsFromOperator
                  ? "1px solid rgba(255,255,255, 0.13)"
                  : "none",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label="LinePlannedStops1" top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Operatörden Gelen Duruşlar
                  </Typography>
                </Box>
                <Tooltip
                  title={expandedErrorsFromOperator ? "Daralt" : "Genişlet"}
                >
                  <IconButton
                    sx={{
                      borderRadius: 1,
                      width: 24,
                      height: 24,
                      background: "rgba(255,255,255,0.13)",
                      border: "1px solid rgba(255,255,255,0.24)",
                    }}
                    onClick={() =>
                      setExpandedErrorsFromOperator(!expandedErrorsFromOperator)
                    }
                  >
                    {expandedErrorsFromOperator ? (
                      <KeyboardArrowUpRounded
                        sx={{
                          color: "#f0f3f4",
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownRounded
                        sx={{
                          color: "#f0f3f4",
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography
                sx={{
                  pr: 0.5,
                  color: "#f0f3f4",
                }}
              >
                  {operatorsErrorSum()} dk
                  </Typography>
            </Box> */}
            {expandedUnplannedPaused &&
              productivityInfoData.ErrorsFromOperator?.map(
                (subItem, subIndex) => (
                  <Box
                    key={subIndex}
                    sx={{
                      width: "calc(100% - 36px)",
                      display: "flex",
                      flexDirection: "column",
                      py: 0.5,
                      ml: 4,
                      gap: 0.5,
                      borderBottom: "1px solid rgba(255,255,255, 0.13)",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          flex: 0.66,
                          minWidth: { xs: "100%", sm: "200px" },
                          position: "relative",
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-start" },
                          gap: 1.5,
                        }}
                      >
                        <Typography
                          sx={{
                            color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                          }}
                        >
                          {subItem.title}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          flex: 0.18,
                          minWidth: { xs: "100%", sm: "128px" },
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-start" },
                          alignItems: "center",
                          color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                          border: `1px dashed rgba(${returnErrorColor(MachineType.GENERAL)}, 0.81)`,
                          background: `rgba(${returnErrorColor(MachineType.GENERAL)}, 0.21)`,
                          borderRadius: 1,
                          px: 1,
                          py: 0.5,
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TouchAppRounded
                            sx={{
                              fontSize: "1.1rem",
                              color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography>GENEL</Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          flex: 0.16,
                          minWidth: { xs: "100%", sm: "100px" },
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-end" },
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                            mr: 0.5,
                          }}
                        >
                          {generateTime(subItem.duration)}
                        </Typography>
                      </Box>
                    </Box>
                    {subItem.description && (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <InfoTwoTone
                          sx={{
                            fontSize: "1rem",
                            color: "#d0d3d4",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#f0f3f4",
                            fontSize: "0.81rem",
                          }}
                        >
                          {subItem.description}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )
              )}
            {expandedUnplannedPaused &&
              machines.map((item) => (
                <>
                  {item.ErrorsFromOperator?.map((subItem, subIndex) => (
                    <Box
                      key={subIndex}
                      sx={{
                        width: "calc(100% - 36px)",
                        display: "flex",
                        flexDirection: "column",
                        py: 0.5,
                        ml: 4,
                        gap: 0.5,
                        borderBottom: "1px solid rgba(255,255,255, 0.13)",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: { xs: "center", sm: "space-between" },
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            flex: 0.66,
                            minWidth: { xs: "100%", sm: "200px" },
                            position: "relative",
                            display: "flex",
                            justifyContent: { xs: "center", sm: "flex-start" },
                            gap: 1.5,
                          }}
                        >
                          <Typography
                            sx={{
                              color: `rgb(${returnErrorColor(item.machine_type)})`,
                            }}
                          >
                            {subItem.title}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            flex: 0.18,
                            minWidth: { xs: "100%", sm: "128px" },
                            display: "flex",
                            justifyContent: { xs: "center", sm: "flex-start" },
                            alignItems: "center",
                            color: `rgb(${returnErrorColor(item.machine_type)})`,
                            border: `1px dashed rgba(${returnErrorColor(item.machine_type)}, 0.81)`,
                            background: `rgba(${returnErrorColor(item.machine_type)}, 0.21)`,
                            borderRadius: 1,
                            px: 1,
                            py: 0.5,
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TouchAppRounded
                              sx={{
                                fontSize: "1.1rem",
                                color: `rgb(${returnErrorColor(item.machine_type)})`,
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography>{item.machine_type}</Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            flex: 0.16,
                            minWidth: { xs: "100%", sm: "100px" },
                            display: "flex",
                            justifyContent: { xs: "center", sm: "flex-end" },
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              color: `rgb(${returnErrorColor(item.machine_type)})`,
                              mr: 0.5,
                            }}
                          >
                            {generateTime(subItem.duration)}
                          </Typography>
                        </Box>
                      </Box>
                      {subItem.description && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <InfoTwoTone
                            sx={{
                              fontSize: "1rem",
                              color: "#d0d3d4",
                            }}
                          />
                          <Typography
                            sx={{
                              color: "#f0f3f4",
                              fontSize: "0.81rem",
                            }}
                          >
                            {subItem.description}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ))}
                </>
              ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                color: "#f0f3f4",
                fontWeight: 700,
                fontSize: "1.15rem",
              }}
            >
              Hesaplamalar
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(211deg 29% 25%) 0%, hsl(222deg 33% 18%) 59%, hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo
                    label={"LineProductionTime"}
                    top={0}
                    left={0}
                  />
                  <Typography
                    sx={{
                      color: "#16A085",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    Planlanan Üretim Süresi
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#16A085",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>
                  <Typography
                    sx={{
                      color: "#d0ece7",
                      fontStyle: "italic",
                    }}
                  >
                    Vardiya Uzunluğu - Molalar
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "rgb(22, 160, 133)",
                  fontWeight: 700,
                  fontSize: "1.25rem",
                }}
              >
                {productivityInfoData.PlannedProductionTime} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineShiftLength"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Vardiya Uzunluğu
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.ShiftLength} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineBreaks"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Molalar
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.Breaks} dk
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(211deg 29% 25%) 0%, hsl(222deg 33% 18%) 59%, hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label={"LineWorkingTime"} top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#AF7AC5",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    Çalışma Süresi
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#AF7AC5",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>
                  <Typography
                    sx={{
                      color: "#efe4f3",
                      fontStyle: "italic",
                    }}
                  >
                    Planlanan Üretim Süresi - Durma Süresi
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "#AF7AC5",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                {productivityInfoData.WorkingTime &&
                  (productivityInfoData.WorkingTime / 60).toFixed(0)}{" "}
                dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo
                  label={"LinePlannedProductionTime"}
                  top={0}
                  left={0}
                />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanan Üretim Süresi
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.PlannedProductionTime} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineDownTime"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Durma Süresi (Planlanmış Duruşlar + Planlanmamış Duruşlar)
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.DownTime &&
                  (productivityInfoData.DownTime / 60).toFixed(0)}{" "}
                dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 48px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 6,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineUnplannedStops"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanmamış Duruşlar (ör. Arızalar)
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.UnplannedStops &&
                  productivityInfoData.UnplannedStops.toFixed(0)}{" "}
                dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 48px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 6,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LinePlannedStops"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanmış Duruşlar (ör. Ayarlamalar)
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.PlannedStops} dk
              </Typography>
            </Box>
          </Box>

          {/* 
            <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(211deg 29% 25%) 0%, hsl(222deg 33% 18%) 59%, hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label={"LineGoodCount"} top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#4DD0E1",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    İyi Sayım
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#4DD0E1",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>

                  <Typography
                    sx={{
                      color: "#dbf6f9",
                      fontStyle: "italic",
                    }}
                  >
                    Toplam Üretilen Adet - Reddedilen Adet
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "#4DD0E1",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                {productivityInfoData.GoodCount} adet
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo
                  label={"LineVerifiedBottleTotalPieces"}
                  top={0}
                  left={0}
                />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Toplam Üretilen Adet
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.VerifiedBottleTotalPieces} adet
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo
                  label={"LineRejectTotalPieces"}
                  top={0}
                  left={0}
                />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Reddedilen Adet
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.RejectTotalPieces} adet
              </Typography>
            </Box>
          </Box>
  */}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(225deg 15% 15%) 0%, hsl(229deg 25% 13%) 59%,hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderTop: "1px solid rgba(255, 139, 0, 0.48)",
              borderLeft: "1px solid rgba(255, 139, 0, 0.48)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label={"LineAvailability"} top={0} left={0} />
                  <Typography
                    sx={{
                      color: "rgb(255, 139, 0)",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    Kullanılabilirlik
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "rgb(255, 139, 0)",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      color: "#F6E2D7",
                    }}
                  >
                    Çalışma Süresi / Planlanan Üretim Süresi
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "rgb(255, 139, 0)",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                {productivityInfoData.Availability &&
                  productivityInfoData.Availability.toFixed(1)}{" "}
                %
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineWorkingTime"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Çalışma Süresi
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.WorkingTime &&
                  (productivityInfoData.WorkingTime / 60).toFixed(0)}{" "}
                dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo
                  label={"LinePlannedProductionTime"}
                  top={0}
                  left={0}
                />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanan Üretim Süresi
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.PlannedProductionTime} dk
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(225deg 15% 15%) 0%, hsl(229deg 25% 13%) 59%,hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderTop: "1px solid rgba(253, 202, 64, 0.48)",
              borderLeft: "1px solid rgba(253, 202, 64, 0.48)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label={"LinePerfomance"} top={0} left={0} />
                  <Typography
                    sx={{
                      color: "rgb(253, 202, 64)",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    Performans (Performance)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "rgb(253, 202, 64)",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}>
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      color: "#F5EEDD",
                    }}
                  >
                    (Toplam Üretilen Adet / (Planlanan Üretim Süresi - Planlanmış Duruşlar))
                  </Typography>
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      color: "#F5EEDD",
                    }}
                  >
                    / İdeal Çalışma Oranı
                  </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "rgb(253, 202, 64)",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                {productivityInfoData.Performance &&
                  productivityInfoData.Performance.toFixed(1)}{" "}
                %
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo
                  label={"LineVerifiedBottleTotalPieces"}
                  top={0}
                  left={0}
                />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Toplam Üretilen Adet
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.VerifiedBottleTotalPieces} adet
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LinePlannedProductionTime"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanan Üretim Süresi
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.PlannedProductionTime &&
                  (productivityInfoData.PlannedProductionTime).toFixed(0)}{" "}
                dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LinePlannedStops"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanmış Duruşlar
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.PlannedStops &&
                  (productivityInfoData.PlannedStops).toFixed(0)}{" "}
                dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineIdealRunRatePPM"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  İdeal Çalışma Oranı (Dakikada Üretilen Adet)
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.IdealRunRatePPM} adet/dk
              </Typography>
            </Box>
          </Box>

          {/* 
            <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(225deg 15% 15%) 0%, hsl(229deg 25% 13%) 59%,hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.26)",
              borderTop: "1px solid rgba(36, 109, 255, 0.48)",
              borderLeft: "1px solid rgba(36, 109, 255, 0.48)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label={"LineQuality"} top={0} left={0} />
                  <Typography
                    sx={{
                      color: "rgb(36, 109, 255)",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    Kalite (Quality)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "rgb(36, 109, 255)",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      color: "#CFDCFF",
                    }}
                  >
                    İyi Sayım / Toplam Üretilen Adet
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "rgb(36, 109, 255)",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                {productivityInfoData.Quality &&
                  productivityInfoData.Quality.toFixed(1)}{" "}
                %
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineGoodCount"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  İyi Sayım
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.GoodCount} adet
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo
                  label={"LineVerifiedBottleTotalPieces"}
                  top={0}
                  left={0}
                />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Toplam Üretilen Adet
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.VerifiedBottleTotalPieces} adet
              </Typography>
            </Box>
          </Box>
  */}

          {/* 
            <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(225deg 15% 15%) 0%, hsl(229deg 25% 13%) 59%,hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderTop: "1px solid rgba(1, 173, 84, 0.48)",
              borderLeft: "1px solid rgba(1, 173, 84, 0.48)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label={"LineOEE"} top={0} left={0} />
                  <Typography
                    sx={{
                      color: "rgb(1, 174, 84)",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    Verim (OEE)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "rgb(1, 174, 84)",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>
                  <Typography
                    sx={{
                      color: "#C6E6DB",
                      fontStyle: "italic",
                    }}
                  >
                    Kullanılabilirlik x Performans x Kalite
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "rgb(1, 174, 84)",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                {productivityInfoData.OEE &&
                  productivityInfoData.OEE.toFixed(1)}{" "}
                %
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineAvailability"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Kullanılabilirlik
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.Availability &&
                  productivityInfoData.Availability.toFixed(1)}{" "}
                %
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LinePerformance"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Performans
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.Performance &&
                  productivityInfoData.Performance.toFixed(1)}{" "}
                %
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineQuality"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Kalite
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {productivityInfoData.Quality &&
                  productivityInfoData.Quality.toFixed(1)}{" "}
                %
              </Typography>
            </Box>
          </Box>*/}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProductivityInfoDialog;

// ** Icon Imports
import {
  AdjustRounded,
  FilterAltOffRounded,
  KeyboardArrowDown,
  PersonRounded,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";

// ** MUI Imports
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

// ** React Imports
import { useEffect, useState } from "react";

// ** Types
type OperatorCustomizedFilterButtonProps = {
  operators: string[];
  setOperators: (value: React.SetStateAction<string[]>) => void;
};

// ** Styled Components
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 7,
    backgroundColor: 'rgba(0,0,0,0.5)',
    background: "rgba(255, 255, 255, 0.13) !important",
    backdropFilter: "blur(16px)",
    minWidth: 210,
    boxShadow: "0px 0px 15px 15px rgba(0,0,0,0.1)",
    "& .MuiMenu-list": {
      padding: 0,
    },
    "& .MuiMenuItem-root": {
      borderRadius: 4,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: 'rgb(236, 240, 241)',
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.secondary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    color: 'rgb(236, 240, 241)',
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      marginTop: 0,
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:last-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const OperatorCustomizedFilterButton = (
  props: OperatorCustomizedFilterButtonProps
) => {
  // ** Props
  const { operators, setOperators } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [onlyOneOperator, setOnlyOneOperator] = useState<string | null>(null);
  const [cleanFilteredVisibility, setCleanFilteredVisibility] = useState<boolean>(false);

  const open = Boolean(anchorEl);

  // ** Seçili menü fonksiyonu tetiklendiğinde
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // ** Menüyü kapat
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOperators = (
    event: React.MouseEvent<HTMLElement>,
    pValue: string[]
  ) => {
    if (pValue !== null) {
      if (pValue.length > 1) {
        setOnlyOneOperator(null);
      }

      setOperators(pValue);
    } else {
      setOperators([]);
      setOnlyOneOperator(null);
    }
  };

  const handleOneOperator = (
    event: React.MouseEvent<HTMLElement>,
    pValue: string | null
  ) => {
    if (pValue !== null) {
      setOnlyOneOperator(pValue);
      setOperators([pValue]);
    } else {
      setOnlyOneOperator(null);
    }
  };

  useEffect(() => {
    if(operators.length > 0) {
      setCleanFilteredVisibility(true)
    } else {
      setCleanFilteredVisibility(false)
    }
  }, [operators])

  const generateFilterButtons = () => {
    if (operators.length > 0) {
      return (
        <Tooltip title={`${operators.length} adet operator seçildi.`}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            borderRadius: 1,
            px: 1,
            py: 0.5,
            border: "0.07rem solid rgba(2, 224, 164, 0.33) !important",
            color: "rgb(2, 224, 164)",
            backgroundColor: "rgba(2, 224, 164, 0.21)",
            "&:hover": {
              backgroundColor: "rgba(2, 224, 164, 0.33)",
            },
          }}>
            <PersonRounded />
            <Typography sx={{
              textTransform: 'capitalize',
              fontWeight: 600,
              fontSize: '0.9rem',
              mr: 1,
            }}>
              {`+${operators.length}`}
            </Typography>
          </Box>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Operatör Seçimi">
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            borderRadius: 1,
            px: 1,
            py: 0.5,
            border: "0.07rem solid rgba(236, 240, 241, 0.33) !important",
            color: "rgb(236, 240, 241)",
            backgroundColor: "rgba(236, 240, 241, 0.21)",
            "&:hover": {
              backgroundColor: "rgba(236, 240, 241, 0.33)",
            },
          }}>
            <PersonRounded />
            <Typography sx={{
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '0.9rem',
              mr: 1,
            }}>
              Operatör
            </Typography>
          </Box>
        </Tooltip>
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-start',
      }}
    >
            <IconButton sx={{
        width: 24,
        height: 46,
        display: cleanFilteredVisibility ? "flex" : "none",
        justifyContent: 'center',
        color: "rgb(231, 76, 60)",
        backgroundColor: "rgba(231, 76, 60, 0.24)",
        "&:hover": {
          backgroundColor: "rgba(231, 76, 60, 0.36)",
        },
        alignItems: 'center',
        borderRadius: 1,
        px: 2.5,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }}
      onClick={() => {
        setOperators([]);
        setOnlyOneOperator(null);
        setCleanFilteredVisibility(false)
      }}
      >
        <FilterAltOffRounded fontSize="small" />
      </IconButton>
      <Button
        id="operator-customized-filter-button"
        aria-controls={open ? "operator-customized-filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        sx={{
          pr: 2,
          color: "rgb(236, 240, 241)",
          backgroundColor: "rgba(236, 240, 241, 0.12)",
          "&:hover": {
            backgroundColor: "rgba(236, 240, 241, 0.21)",
          },
          borderTopLeftRadius: cleanFilteredVisibility ? 0 : 5,
          borderBottomLeftRadius: cleanFilteredVisibility ? 0 : 5,
        }}
        endIcon={<KeyboardArrowDown />}
      >
        <Box display="flex" justifyContent="flex-end">
          {generateFilterButtons()}
        </Box>
      </Button>
      <StyledMenu
        id="operator-customized-filter-menu"
        MenuListProps={{
          "aria-labelledby": "operator-customized-filter-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          <Card
            sx={{
              m: 1,
            }}
          >
            <CardContent sx={{ p: 1, pb: "0.5rem !important", background: 'rgba(0,0,0, 0.33)' }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="stretch"
              >
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <StyledToggleButtonGroup
                    fullWidth
                    color="primary"
                    orientation="vertical"
                    value={operators}
                    onChange={handleOperators}
                    aria-label="button-filter-operator-group"
                  >
                    <ToggleButton
                      value="OĞUZ UYAR"
                      aria-label="OĞUZ UYAR"
                      sx={{
                        height: 32,
                        marginTop: "0.15rem !important",
                        marginBottom: "0.15rem !important",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "rgba(236, 240, 241, 0.21)",
                        },
                        "&.Mui-selected, &.Mui-selected:hover": {
                          color: "rgb(2, 224, 164)",
                          backgroundColor: "rgba(2, 224, 164, 0.21)",
                          "& .MuiSvgIcon-root": {
                            color: "rgb(2, 224, 164)",
                          },
                        },
                      }}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap={1}
                      >
                        <PersonRounded
                          fontSize="small"
                        />
                        <Box>OĞUZ UYAR</Box>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      value="SELAMİ VURAL"
                      aria-label="SELAMİ VURAL"
                      sx={{
                        height: 32,
                        marginTop: "0.15rem !important",
                        marginBottom: "0.15rem !important",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "rgba(236, 240, 241, 0.21)",
                        },
                        "&.Mui-selected, &.Mui-selected:hover": {
                          color: "rgb(2, 224, 164)",
                          backgroundColor: "rgba(2, 224, 164, 0.21)",
                          "& .MuiSvgIcon-root": {
                            color: "rgb(2, 224, 164)",
                          },
                        },
                      }}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap={1}
                      >
                        <PersonRounded
                          fontSize="small"
                        />
                        <Box>SELAMİ VURAL</Box>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      value="FATİH ŞAHİN"
                      aria-label="FATİH ŞAHİN"
                      sx={{
                        height: 32,
                        marginTop: "0.15rem !important",
                        marginBottom: "0.15rem !important",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "rgba(236, 240, 241, 0.21)",
                        },
                        "&.Mui-selected, &.Mui-selected:hover": {
                          color: "rgb(2, 224, 164)",
                          backgroundColor: "rgba(2, 224, 164, 0.21)",
                          "& .MuiSvgIcon-root": {
                            color: "rgb(2, 224, 164)",
                          },
                        },
                      }}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap={1}
                      >
                        <PersonRounded
                          fontSize="small"
                        />
                        <Box>FATİH ŞAHİN</Box>
                      </Box>
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                </Box>
                <Box sx={{ display: "flex", flexWrap: "wrap", ml: 1 }}>
                  <StyledToggleButtonGroup
                    fullWidth
                    exclusive
                    color="primary"
                    orientation="vertical"
                    value={onlyOneOperator}
                    onChange={handleOneOperator}
                    aria-label="button-filter-only-operator-group"
                  >
                    <Tooltip title="Sadece OĞUZ UYAR">
                      <ToggleButton
                        value="OĞUZ UYAR"
                        aria-label="OĞUZ UYAR"
                        sx={{
                          maxHeight: 32,
                          maxWidth: 32,
                          marginTop: "0.15rem !important",
                          marginBottom: "0.15rem !important",
                          textTransform: "capitalize",
                          "&.Mui-selected, &.Mui-selected:hover": {
                            color: "rgb(2, 224, 164)",
                            backgroundColor: "rgba(2, 224, 164, 0.21)",
                            "& .MuiSvgIcon-root": {
                              color: "rgb(2, 224, 164)",
                            },
                          },
                        }}
                      >
                        {onlyOneOperator === "OĞUZ UYAR" ? (
                          <AdjustRounded fontSize="small" />
                        ) : (
                          <RadioButtonUncheckedRounded fontSize="small" />
                        )}
                      </ToggleButton>
                    </Tooltip>
                    <Tooltip title="Sadece SELAMİ VURAL">
                      <ToggleButton
                        value="SELAMİ VURAL"
                        aria-label="SELAMİ VURAL"
                        sx={{
                          maxHeight: 32,
                          maxWidth: 32,
                          marginTop: "0.15rem !important",
                          marginBottom: "0.15rem !important",
                          textTransform: "capitalize",
                          "&.Mui-selected, &.Mui-selected:hover": {
                            color: "rgb(2, 224, 164)",
                            backgroundColor: "rgba(2, 224, 164, 0.21)",
                            "& .MuiSvgIcon-root": {
                              color: "rgb(2, 224, 164)",
                            },
                          },
                        }}
                      >
                        {onlyOneOperator === "SELAMİ VURAL" ? (
                          <AdjustRounded fontSize="small" />
                        ) : (
                          <RadioButtonUncheckedRounded fontSize="small" />
                        )}
                      </ToggleButton>
                    </Tooltip>
                    <Tooltip title="Sadece FATİH ŞAHİN">
                      <ToggleButton
                        value="FATİH ŞAHİN"
                        aria-label="FATİH ŞAHİN"
                        sx={{
                          maxHeight: 32,
                          maxWidth: 32,
                          marginTop: "0.15rem !important",
                          marginBottom: "0.15rem !important",
                          textTransform: "capitalize",
                          "&.Mui-selected, &.Mui-selected:hover": {
                            color: "rgb(2, 224, 164)",
                            backgroundColor: "rgba(2, 224, 164, 0.21)",
                            "& .MuiSvgIcon-root": {
                              color: "rgb(2, 224, 164)",
                            },
                          },
                        }}
                      >
                        {onlyOneOperator === "FATİH ŞAHİN" ? (
                          <AdjustRounded fontSize="small" />
                        ) : (
                          <RadioButtonUncheckedRounded fontSize="small" />
                        )}
                      </ToggleButton>
                    </Tooltip>
                  </StyledToggleButtonGroup>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </StyledMenu>
    </Box>
  );
};

export default OperatorCustomizedFilterButton;

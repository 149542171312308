// ** Icons Imports
import {
  AssignmentIndRounded,
  AssignmentIndTwoTone,
  CheckCircleRounded,
  CheckCircleTwoTone,
  CloseRounded,
  DoneRounded,
  FlagTwoTone,
  PersonRounded,
} from "@mui/icons-material";
import MuiToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";

// ** MUI Imports
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { ShiftInfoChange } from "../pages/InputsPage";
import axios from "axios";

// ** Types
type BreakChangeDialogProps = {
  accessToken: string;
  shiftInfo: ShiftInfoChange;
  setShiftInfo: (value: React.SetStateAction<ShiftInfoChange>) => void;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

// Styled ToggleButton component
const ToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(
  ({ theme }) => ({
    border: "none !important",
    textTransform: "capitalize",
    fontWeight: 700,
    color: "#f0f3f4",
    "&.Mui-disabled": {
      color: "#f0f3f4",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backdropFilter: "blur(16px)",
      color: "#f0f3f4",
      backgroundColor: "rgba(255, 255, 255, 0.13)",
    },
    "&:not(:first-of-type)": {
      borderRadius: `13px !important`,
    },
    "&:first-of-type": {
      borderRadius: `13px !important`,
    },
  })
);

const API_URL = process.env.REACT_APP_API_HOST || "";

const BreakChangeDialog = (props: BreakChangeDialogProps) => {
  // ** Props
  const { shiftInfo, setShiftInfo, dialogState, setDialogState, accessToken } =
    props;

  // ** States
  const [value, setValue] = useState<String | null>(shiftInfo.operatorFullName);
  const [submitError, setSubmitError] = useState<boolean>(false);

  const handleOperatorChange = (
    event: MouseEvent<HTMLElement>,
    pValue: string | null
  ) => {
    setValue(pValue);
    setShiftInfo({ ...shiftInfo, operatorFullName: pValue });
  };

  // ** Functions
  const onClose = () => {
    setDialogState(false);
  };

  // **
  const onSaveShiftInfo = () => {
    if(value) {
      if (shiftInfo.value) {
        axios
          .post(
            API_URL + "/setStartLine",
            {
              val: false,
              operator_name: shiftInfo.operatorFullName,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-token": accessToken,
              },
            }
          )
          .then((result) => {
            if (result.data === false) {
              setShiftInfo({ ...shiftInfo, value: false });
              setDialogState(false);
            }
          });
      } else {
        axios
          .post(
            API_URL + "/setStartLine",
            {
              val: true,
              operator_name: shiftInfo.operatorFullName,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-token": accessToken,
              },
            }
          )
          .then((result) => {
            if (result.data === true) {
              setShiftInfo({ ...shiftInfo, value: true });
              setDialogState(false);
            }
          });
      }
      setSubmitError(false)
    } else {
      setSubmitError(true)
    }

  };

  useEffect(() => {
    setValue(shiftInfo.operatorFullName);
  }, [shiftInfo.operatorFullName]);

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="lg"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          backgroundColor: "rgba(255, 255, 255, 0.13) !important",
        },
      }}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.72)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <FlagTwoTone
              fontSize="large"
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "calc(1rem + 1vh)",
              color: "#f0f3f4",
              fontWeight: 500,
            }}
          >
            {shiftInfo ? "Vardiya Bitir" : "Vardiya Başlat"}
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="large"
            aria-label="settings"
          >
            <CloseRounded fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ m: 0, p: 3.5, background: "rgba(0,0,0, 0.42)" }}>
        <Box>
          <Box
            sx={{
              width: "100%",
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "calc(0.85rem + 1vh)",
                color: "#f0f3f4",
              }}
            >
              {shiftInfo
                ? "Vardiyayı bitirmek için onay gerekiyor."
                : "Vardiyayı başlatmak için operatör seçimi ve onay gerekiyor."}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                background: "rgba(0,0,0, 0.33)",
                px: 3,
                pt: 2,
                pb: 3,
                borderRadius: 3,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 4,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.45rem",
                    color: submitError ? "#e74c3c" : "#f0f3f4",
                  }}
                >
                  Operatör Seçimi
                </Typography>
              </Box>
              <ToggleButtonGroup
                exclusive
                value={value}
                onChange={handleOperatorChange}
                aria-label="operator-button-toggle"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  flexWrap: "wrap",
                }}
                disabled={shiftInfo.value}
              >
                <ToggleButton value="OĞUZ UYAR" aria-label="OĞUZ UYAR">
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "column",
                      px: 2,
                      pt: 5,
                      pb: 1,
                      gap: 1.5,
                    }}
                  >
                    {value === "OĞUZ UYAR" && (
                      <CheckCircleRounded
                        sx={{
                          position: "absolute",
                          top: -32,
                          fontSize: "3rem",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AssignmentIndRounded
                        sx={{
                          fontSize: "calc(1.1rem + 7.1vh)",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "calc(0.67rem + 1vh)",
                      }}
                    >
                      OĞUZ UYAR
                    </Typography>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton value="SELAMİ VURAL" aria-label="medium">
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "column",
                      px: 2,
                      pt: 5,
                      pb: 1,
                      gap: 1.5,
                    }}
                  >
                    {value === "SELAMİ VURAL" && (
                      <CheckCircleRounded
                        sx={{
                          position: "absolute",
                          top: -32,
                          fontSize: "3rem",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AssignmentIndRounded
                        sx={{
                          fontSize: "calc(1.1rem + 7.1vh)",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "calc(0.67rem + 1vh)",
                      }}
                    >
                      SELAMİ VURAL
                    </Typography>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton value="FATİH ŞAHİN" aria-label="ship">
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "column",
                      px: 2,
                      pt: 5,
                      pb: 1,
                      gap: 1.5,
                    }}
                  >
                    {value === "FATİH ŞAHİN" && (
                      <CheckCircleRounded
                        sx={{
                          position: "absolute",
                          top: -32,
                          fontSize: "3rem",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AssignmentIndRounded
                        sx={{
                          fontSize: "calc(1.1rem + 7.1vh)",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "calc(0.67rem + 1vh)",
                      }}
                    >
                      FATİH ŞAHİN
                    </Typography>
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 3,
              mt: 3,
              maxWidth: "78%",
              mx: "auto",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                flex: 1,
                color: "#f0f3f4",
                fontSize: "1.5rem",
                height: 72,
                borderColor: "rgba(255, 255, 255, 0.36)",
              }}
              onClick={() => setDialogState(false)}
            >
              İptal
            </Button>
            <Button
              sx={{
                flex: 1,
                fontSize: "1.5rem",
                color: "#212121",
                height: 72,
                background: "rgba(255, 255, 255, 0.67)",
              }}
              variant="contained"
              onClick={onSaveShiftInfo}
              startIcon={
                <DoneRounded
                  sx={{
                    fontSize: "2.5rem !important",
                  }}
                />
              }
            >
              Onayla
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BreakChangeDialog;

import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Container, Typography } from "@mui/material";
import {
  InfoTwoTone,
  MoodRounded,
  PersonRounded,
  SensorsRounded,
  SentimentDissatisfiedRounded,
  SentimentNeutralRounded,
  SentimentSatisfiedAltRounded,
  TouchAppRounded,
} from "@mui/icons-material";
import { i18n } from "dateformat";
import axios from "axios";
import { MachineType, Message } from "../message";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useParams } from "react-router-dom";
import { generateTime } from "../components/LineDownInfoDialog";
import { ShiftType, VirtualShiftFilter } from "../App";

export type ErrorInfoChange = {
  title: string;
  isStarted: boolean;
  machine: any;
  startedAt: number;
  duration: number;
  solvedAt: number;
  description?: string;
};

export type ShiftInfoChange = {
  value: boolean;
  operatorFullName: string | null;
};

const API_URL = process.env.REACT_APP_API_HOST || "";

function ReportsPage() {
  const { shiftFilter, shiftType, withToolbar } = useParams();

  // Settings
  i18n.dayNames = [
    "Paz",
    "Pzt",
    "Sal",
    "Çar",
    "Per",
    "Cum",
    "Cmt",
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];

  // ** States
  const [message, setMessage] = useState<Message>({
    Machines: [],
    Line: {
      Availability: 0,
      Performance: 0,
      Quality: 0,
      OEE: 0,
      Breaks: 0,
      Breaks1: 0,
      Breaks2: 0,
      DolumBottleOutCounter: 0, // ** Dolan Şişe
      DolumTurnTableFull: false,
      DownTime: 0,
      GoodCount: 0,
      Red: false,
      Yellow: false,
      Green: false,
      IdealRunRatePPM: 0,
      InBottlePieces: 0, // ** Giren Şişe
      KutulamaBoxRejectCounter: 0, // ** Kutulama Fire
      PackageOutPieces: 0, // ** Çıkan Koli
      PlannedProductionTime: 0,
      PlannedStops: 0,
      PlannedStops1: 0,
      PlannedStops2: 0,
      PresentTime: 0,
      RecycleBottles: 0,
      RejectBottleTotalPieces: 0, // ** Etiket Fire
      RejectTotalPieces: 0,
      VerifiedBoxTotalPieces: 0, // ** Kutulanan Ürün
      UnfillableBottlePieces: 0, // ** Dolum Fire
      VerifiedBottleTotalPieces: 0, // ** Etiketli Ürün - Çıkan Ürün
      BreaksTime: 0,
      WorkingTime: 0,
      ShiftLength: 0,
      UnplannedStops: 0,
      ErrorsFromOperator: [],
      StartProduction: false,
      StartBreaks: false,
      StartScheduledStops: false,
    },
    Time: 0,
    Connected: false,
  });

  const [shiftFilterDetail, setShiftFilterDetail] =
    useState<VirtualShiftFilter>({
      operators: [],
      started_at: "",
      finished_at: "",
      page: 1,
    });

  const [productivityColor, setProductivityValue] =
    useState<string>("2, 224, 164");
  const [productivityEmoji, setProductivityEmoji] = useState<any>();

  const returnColor = () => {
    if (message.Line.OEE >= 85) {
      return "2, 224, 164";
    }

    if (message.Line.OEE >= 60) {
      return "131, 217, 20";
    }

    if (message.Line.OEE >= 40) {
      return "243, 156, 18";
    }

    return "255, 76, 81";
  };

  const returnErrorColor = (machineType: MachineType | undefined) => {
    if (machineType === "GENERAL") {
      //return "#F48FB1";
      return "244, 143, 177";
    } else if (machineType === "BESLEME") {
      //return "#80CBC4";
      return "128, 203, 196";
    } else if (machineType === "DOLUM") {
      //return "#FFAB91";
      return "255, 171, 145";
    } else if (machineType === "ETIKET") {
      //return "#81D4FA";
      return "129, 212, 250";
    } else if (machineType === "KUTULAMA") {
      //return "#B39DDB";
      return "179, 157, 219";
    } else if (machineType === "ITS") {
      //return "#A5D6A7";
      return "165, 214, 167";
    } else if (machineType === "KOLILEME") {
      //return "#e85454";
      return "232, 84, 84";
    }

    //return "#f0f3f4";
    return "240, 243, 244";
  };

  const returnEmoji = () => {
    if (message.Line.OEE >= 85) {
      return (
        <MoodRounded
          sx={{
            fontSize: 52,
          }}
        />
      );
    }

    if (message.Line.OEE >= 60) {
      return <SentimentSatisfiedAltRounded sx={{ fontSize: 52 }} />;
    }

    if (message.Line.OEE >= 40) {
      return (
        <SentimentNeutralRounded
          sx={{
            fontSize: 52,
          }}
        />
      );
    }

    return (
      <SentimentDissatisfiedRounded
        sx={{
          fontSize: 52,
        }}
      />
    );
  };

  const sensorsErrorSum = () => {
    let sum = 0;

    message.Machines.forEach((d) => {
      d.Sensors.forEach((item) => {
        if (item.sensor_type === "ERROR" && item.time) {
          sum = sum + Number(item.time);
        }
      });
    });

    return (sum / 60).toFixed(0);
  };

  const operatorsErrorSum = () => {
    let sum = 0;

    message.Machines.forEach((d) => {
      d.ErrorsFromOperator?.forEach((item) => {
        sum = sum + Number(item.duration);
      });
    });

    return (sum / 60).toFixed(0);
  };

  const onPDF = () => {
    if (shiftType === ShiftType.shift) {
      window.open(`${API_URL}/getPDF/shift/${shiftFilter}`, "_blank");
    }

    if (shiftType === ShiftType.virtual_shift) {
      window.open(`${API_URL}/getPDF/virtual-shift/${shiftFilter}`, "_blank");
    }
  };

  const onEXCEL = () => {
    if (shiftType === ShiftType.shift) {
      window.open(`${API_URL}/getExcel/shift/${shiftFilter}`, "_blank");
    }

    if (shiftType === ShiftType.virtual_shift) {
      const shiftFilterDetail = JSON.parse(
        atob(decodeURIComponent(shiftFilter as string))
      );

      window.open(
        `${API_URL}/getExcel/virtual-shift?started_at=${shiftFilterDetail.started_at}&finished_at=${shiftFilterDetail.finished_at}&operators=[]&page=1`,
        "_blank"
      );
    }
  };

  const returnOperatorDescription = () => {
    if (shiftType === ShiftType.shift) {
      return message.operator_name
        ? message.operator_name
        : "Operatör Seçilmedi";
    }

    if (shiftType === ShiftType.virtual_shift) {
      return shiftFilterDetail.operators.length > 0
        ? shiftFilterDetail.operators.join(", ")
        : "Operatör Seçilmedi";
    }

    return "Operatör Seçilmedi";
  };

  const returnDateDescription = () => {
    if (shiftType === ShiftType.shift) {
      const startedDate = new Date(message.Time * 1000);
      const startedYear = startedDate.getFullYear();
      let startedMonth = (startedDate.getMonth() + 1) as unknown as string;
      let startedDt = startedDate.getDate() as unknown as string;

      if (Number(startedDt) < 10) {
        startedDt = "0" + startedDt;
      }
      if (Number(startedMonth) < 10) {
        startedMonth = "0" + startedMonth;
      }
      return `${startedDt}/${startedMonth}/${startedYear}`;
    }

    if (shiftType === ShiftType.virtual_shift) {
      const startedDate = new Date(shiftFilterDetail.started_at as string);
      const startedYear = startedDate.getFullYear();
      let startedMonth = (startedDate.getMonth() + 1) as unknown as string;
      let startedDt = startedDate.getDate() as unknown as string;

      if (Number(startedDt) < 10) {
        startedDt = "0" + startedDt;
      }
      if (Number(startedMonth) < 10) {
        startedMonth = "0" + startedMonth;
      }

      const finisheddDate = new Date(shiftFilterDetail.finished_at as string);
      const finishedYear = finisheddDate.getFullYear();
      let finishedMonth = (finisheddDate.getMonth() + 1) as unknown as string;
      let finishedDt = finisheddDate.getDate() as unknown as string;

      if (Number(startedDt) < 10) {
        finishedDt = "0" + finishedDt;
      }
      if (Number(startedMonth) < 10) {
        finishedMonth = "0" + finishedMonth;
      }

      return `${startedDt}/${startedMonth}/${startedYear} - ${finishedDt}/${finishedMonth}/${finishedYear} (ORTALAMA)`;
    }

    return "";
  };

  const b64_to_utf8 = (str: string) => decodeURIComponent(escape(atob(str)));

  const getShiftDataDetails = useCallback(async () => {
    if (shiftType === ShiftType.shift) {
      const shiftData = await axios.get(
        `${API_URL}/getShiftData/${shiftFilter}`
      );
      if (shiftData.data) {
        setMessage(shiftData.data);
      }
    }

    if (shiftType === ShiftType.virtual_shift) {
      const shiftFilterDetail = JSON.parse(b64_to_utf8(shiftFilter as string));

      setShiftFilterDetail(shiftFilterDetail);

      const shiftData = await axios.post(
        `${API_URL}/getAverage`,
        shiftFilterDetail
      );
      if (shiftData.data) {
        setMessage(shiftData.data);
      }
    }
  }, []);

  useEffect(() => {
    getShiftDataDetails();
  }, [getShiftDataDetails]);

  useEffect(() => {
    setProductivityValue(returnColor());
    setProductivityEmoji(returnEmoji());
  }, [message]);

  return (
    <div
      style={{
        position: "relative",
        display: "block",
        width: "100dvw !important",
        height: "100dvh",
        maxHeight: "100dvh",
        overflowY: "scroll",
        paddingBottom: 10,
      }}
    >
      {withToolbar === "true" && (
        <Box
          sx={{
            position: "static",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              m: 1,
              borderRadius: 1,
              "&:hover": {
                backgroundColor: "rgba(0,0,0, 0.21)",
              },
            }}
            onClick={onPDF}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32px"
              height="32px"
              viewBox="0 0 56 64"
              enable-background="new 0 0 56 64"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <path
                    fill="#8C181A"
                    d="M5.1,0C2.3,0,0,2.3,0,5.1v53.8C0,61.7,2.3,64,5.1,64h45.8c2.8,0,5.1-2.3,5.1-5.1V20.3L37.1,0H5.1z"
                  ></path>
                  <path
                    fill="#6B0D12"
                    d="M56,20.4v1H43.2c0,0-6.3-1.3-6.1-6.7c0,0,0.2,5.7,6,5.7H56z"
                  ></path>
                  <path
                    opacity="0.5"
                    fill="#FFFFFF"
                    enable-background="new "
                    d="M37.1,0v14.6c0,1.7,1.1,5.8,6.1,5.8H56L37.1,0z"
                  ></path>
                </g>
                <path
                  fill="#FFFFFF"
                  d="M14.9,49h-3.3v4.1c0,0.4-0.3,0.7-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.7V42.9c0-0.6,0.5-1.1,1.1-1.1h3.7 c2.4,0,3.8,1.7,3.8,3.6C18.7,47.4,17.3,49,14.9,49z M14.8,43.1h-3.2v4.6h3.2c1.4,0,2.4-0.9,2.4-2.3C17.2,44,16.2,43.1,14.8,43.1z M25.2,53.8h-3c-0.6,0-1.1-0.5-1.1-1.1v-9.8c0-0.6,0.5-1.1,1.1-1.1h3c3.7,0,6.2,2.6,6.2,6C31.4,51.2,29,53.8,25.2,53.8z M25.2,43.1 h-2.6v9.3h2.6c2.9,0,4.6-2.1,4.6-4.7C29.9,45.2,28.2,43.1,25.2,43.1z M41.5,43.1h-5.8V47h5.7c0.4,0,0.6,0.3,0.6,0.7 s-0.3,0.6-0.6,0.6h-5.7v4.8c0,0.4-0.3,0.7-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.7V42.9c0-0.6,0.5-1.1,1.1-1.1h6.2c0.4,0,0.6,0.3,0.6,0.7 C42.2,42.8,41.9,43.1,41.5,43.1z"
                ></path>
              </g>
            </svg>
            <Typography
              sx={{
                ml: 2,
                color: "#f0f3f4",
              }}
            >
              PDF
            </Typography>
          </Button>
          <Button
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              m: 1,
              borderRadius: 1,
              "&:hover": {
                backgroundColor: "rgba(0,0,0, 0.21)",
              },
            }}
            onClick={onEXCEL}
          >
            <svg
              width="32px"
              height="32px"
              viewBox="-4 0 64 64"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M5.112.006c-2.802 0-5.073 2.273-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.902-20.31h-31.946z"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#45B058"
                ></path>
                <path
                  d="M19.429 53.938c-.216 0-.415-.09-.54-.27l-3.728-4.97-3.745 4.97c-.126.18-.324.27-.54.27-.396 0-.72-.306-.72-.72 0-.144.035-.306.144-.432l3.89-5.131-3.619-4.826c-.09-.126-.145-.27-.145-.414 0-.342.288-.72.721-.72.216 0 .432.108.576.288l3.438 4.628 3.438-4.646c.127-.18.324-.27.541-.27.378 0 .738.306.738.72 0 .144-.036.288-.127.414l-3.619 4.808 3.891 5.149c.09.126.125.27.125.414 0 .396-.324.738-.719.738zm9.989-.126h-5.455c-.595 0-1.081-.486-1.081-1.08v-10.317c0-.396.324-.72.774-.72.396 0 .721.324.721.72v10.065h5.041c.359 0 .648.288.648.648 0 .396-.289.684-.648.684zm6.982.216c-1.782 0-3.188-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.36.27-.756.702-.756.144 0 .306.036.433.144.828.738 1.98 1.314 3.367 1.314 2.143 0 2.826-1.152 2.826-2.071 0-3.097-7.111-1.386-7.111-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.468 3.853 1.278.162.144.253.342.253.54 0 .36-.307.72-.703.72-.145 0-.307-.054-.432-.162-.883-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636 0 1.748-1.188 3.53-4.43 3.53z"
                  fill="#ffffff"
                ></path>
                <path
                  d="M55.953 20.352v1h-12.801s-6.312-1.26-6.127-6.707c0 0 .207 5.707 6.002 5.707h12.926z"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#349C42"
                ></path>
                <path
                  d="M37.049 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.801l-18.905-20.352z"
                  opacity=".5"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#ffffff"
                ></path>
              </g>
            </svg>
            <Typography
              sx={{
                ml: 2,
                color: "#f0f3f4",
              }}
            >
              Excel
            </Typography>
          </Button>
        </Box>
      )}

      <Container
        disableGutters
        maxWidth="lg"
        sx={{
          mt: withToolbar ? 1 : 0,
        }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          alignItems="center"
          sx={{
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1.5,
                  pl: 0.8,
                  pr: 1.5,
                  py: 0.8,
                  borderRadius: 2,
                  maxHeight: 42,
                  background: "rgba(240, 243, 244, 0.21)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "cneter",
                    alignItems: "center",
                    background: "rgba(240, 243, 244, 0.21)",
                    borderRadius: 1,
                    borderBottom: "2px solid rgba(240, 243, 244, 0.81)",
                  }}
                >
                  <PersonRounded
                    fontSize="small"
                    sx={{
                      m: 0.5,
                      color: "#f0f3f4",
                    }}
                  />
                </Box>

                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {returnOperatorDescription()}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#C9CFF2",
                }}
              >
                {returnDateDescription()}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                background: "rgb(33, 36, 45)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderRadius: 1,
                py: 1,
                px: 2,
                gap: 2.5,
              }}
            >
              <InfoTwoTone
                sx={{
                  fontSize: "1.75rem",
                  color: "#d0d3d4",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontStyle: "italic",
                    color: "#f0f3f4",
                  }}
                >
                  OEE (Verim) hesabının anlık hesaplanabilmesi için planlanan
                  üretim süresi yerine aktif çalışma süresi kullanılması
                  gerekir. Vardiya bitiminde ise yeniden planlanan üretim süresi
                  baz alınarak verim hesabı yapılır ve kaydedilir.
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                background: "rgb(33, 36, 45)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 5,
                  px: 3,
                  py: 2,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "rgba(255,255,255,0.72)",
                          }}
                        >
                          Kullanılabilirlik (Availability)
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: 1,
                            background: "#ffa233",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            color: "rgba(255,255,255,0.87)",
                          }}
                        >
                          {message.Line.Availability &&
                            message.Line.Availability.toFixed(1)}{" "}
                          %
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        borderRadius: 1,
                        background: "rgba(255, 139, 0, 0.13)",
                        height: 12,
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          background: "rgb(255, 139, 0)",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: `calc(100% - ${message.Line.Availability}%)`,
                          borderRadius: 1,
                        }}
                      ></Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "rgba(255,255,255,0.72)",
                          }}
                        >
                          Performans (Performance)
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: 1,
                            background: "#fdd566",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "rgba(255,255,255,0.87)",
                          }}
                        >
                          {message.Line.Performance &&
                            message.Line.Performance.toFixed(1)}{" "}
                          %
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        borderRadius: 1,
                        background: "rgba(253, 202, 64, 0.13)",
                        height: 12,
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          background: "rgb(253, 202, 64)",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: `calc(100% - ${message.Line.Performance}%)`,
                          borderRadius: 1,
                        }}
                      ></Box>
                    </Box>
                  </Box>
                  {/*
                  
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "rgb(255,255,255,0.72)",
                          }}
                        >
                          Kalite (Quality)
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: 1,
                            background: "#508aff",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "rgb(255,255,255,0.87)",
                          }}
                        >
                          {message.Line.Quality &&
                            message.Line.Quality.toFixed(1)}{" "}
                          %
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        borderRadius: 1,
                        background: "rgba(36, 109, 255, 0.12)",
                        height: 12,
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          background: "rgb(36, 109, 255)",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: `calc(100% - ${message.Line.Quality}%)`,
                          borderRadius: 1,
                        }}
                      ></Box>
                    </Box>
                  </Box> */}
                </Box>
                {/*
                
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 24,
                      left: 0,
                      right: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      color: `rgb(${productivityColor})`,
                    }}
                  >
                    {productivityEmoji}
                  </Box>
                  <Box
                    sx={{
                      width: 96,
                      height: 96,
                    }}
                  >
                    <CircularProgressbar
                      value={message.Line.OEE}
                      strokeWidth={9}
                      styles={buildStyles({
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: "butt",

                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,

                        // Colors
                        pathColor: `rgba(${productivityColor}, ${90 / 100})`,
                        trailColor: `rgba(${productivityColor}, 0.13)`,
                      })}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: 700,
                      color: "rgb(255,255,255,0.87)",
                    }}
                  >
                    {message.Line.OEE && message.Line.OEE.toFixed(1)} %
                  </Typography>
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundImage:
                  "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Vardiya Uzunluğu
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 2,
                    px: 0.8,
                    py: 0.4,
                    backgroundColor: "rgba(255, 255, 255, 0.13)",
                    ":hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.21)",
                    },
                    border: "1px solid rgb(255, 255, 255, 0.24)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    {message.Line.ShiftLength || 0} dk
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                        fontWeight: 600,
                      }}
                    >
                      Molalar
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 2,
                    px: 0.8,
                    py: 0.4,
                    backgroundColor: "rgba(255, 255, 255, 0.13)",
                    ":hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.21)",
                    },
                    border: "1px solid rgb(255, 255, 255, 0.24)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    {message.Line.Breaks} dk
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Çay ve Yemek
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.Breaks1} dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Diğer
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.Breaks2} dk
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundImage:
                  "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Toplam Üretilen Adet
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 2,
                    px: 0.8,
                    py: 0.4,
                    backgroundColor: "rgba(255, 255, 255, 0.13)",
                    ":hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.21)",
                    },
                    border: "1px solid rgb(255, 255, 255, 0.24)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    {message.Line.VerifiedBottleTotalPieces} adet
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Toplam Reddedilen Adet
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 2,
                    px: 0.8,
                    py: 0.4,
                    backgroundColor: "rgba(255, 255, 255, 0.13)",
                    ":hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.21)",
                    },
                    border: "1px solid rgb(255, 255, 255, 0.24)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    {message.Line.RejectTotalPieces} adet
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Hat Hızı (İdeal Çalışma Oranı)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 2,
                    px: 0.8,
                    py: 0.4,
                    backgroundColor: "rgba(255, 255, 255, 0.13)",
                    ":hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.21)",
                    },
                    border: "1px solid rgb(255, 255, 255, 0.24)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    {message.Line.IdealRunRatePPM} adet/dk
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundImage:
                  "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                        fontWeight: 600,
                      }}
                    >
                      Planlanmış Duruşlar
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 2,
                    px: 0.8,
                    py: 0.4,
                    backgroundColor: "rgba(255, 255, 255, 0.13)",
                    ":hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.21)",
                    },
                    border: "1px solid rgb(255, 255, 255, 0.24)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    {message.Line.PlannedStops} dk
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Temizlik ve Ayar
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    pr: 0.5,
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.PlannedStops1} dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Diğer
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    pr: 0.5,
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.PlannedStops2} dk
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                        fontWeight: 600,
                      }}
                    >
                      Planlanmamış Duruşlar
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 2,
                    px: 0.8,
                    py: 0.4,
                    backgroundColor: "rgba(255, 255, 255, 0.13)",
                    ":hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.21)",
                    },
                    border: "1px solid rgb(255, 255, 255, 0.24)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    {message.Line.UnplannedStops &&
                      message.Line.UnplannedStops.toFixed(0)}{" "}
                    dk
                  </Typography>
                </Box>
              </Box>
              {/* 
              
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                        fontWeight: 600,
                      }}
                    >
                      Sensörden Gelen Duruşlar
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    pr: 0.5,
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {sensorsErrorSum()} dk
                </Typography>
              </Box> */}
              {message.Machines.map((item) => (
                <>
                  {item.Sensors.map((subItem, subIndex) => (
                    <>
                      {subItem.sensor_type === "ERROR" && (
                        <Box
                          key={subIndex}
                          sx={{
                            width: "calc(100% - 36px)",
                            display: "flex",
                            justifyContent: {
                              xs: "center",
                              sm: "space-between",
                            },
                            alignItems: "center",
                            borderBottom: "1px solid rgba(255,255,255, 0.13)",
                            py: 0.5,
                            ml: 4,
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              flex: 0.66,
                              minWidth: { xs: "100%", sm: "200px" },
                              display: "flex",
                              justifyContent: {
                                xs: "center",
                                sm: "flex-start",
                              },
                              gap: 1.5,
                            }}
                          >
                            <Typography
                              sx={{
                                color: `rgb(${returnErrorColor(item.machine_type)})`,
                              }}
                            >
                              {subItem.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              flex: 0.18,
                              minWidth: { xs: "100%", sm: "128px" },
                              display: "flex",
                              justifyContent: {
                                xs: "center",
                                sm: "flex-start",
                              },
                              alignItems: "center",
                              color: `rgb(${returnErrorColor(item.machine_type)})`,
                              border: `1px dashed rgba(${returnErrorColor(item.machine_type)}, 0.81)`,
                              background: `rgba(${returnErrorColor(item.machine_type)}, 0.21)`,
                              borderRadius: 1,
                              px: 1,
                              py: 0.5,
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <SensorsRounded
                                sx={{
                                  fontSize: "1.1rem",
                                  color: `rgb(${returnErrorColor(item.machine_type)})`,
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Typography>{item.machine_type}</Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              flex: 0.16,
                              minWidth: { xs: "100%", sm: "100px" },
                              display: "flex",
                              gap: 1,
                              justifyContent: { xs: "center", sm: "flex-end" },
                            }}
                          >
                            <Typography
                              sx={{
                                color: `rgb(${returnErrorColor(item.machine_type)})`,
                                mr: 0.5,
                              }}
                            >
                              {generateTime(subItem.time)}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  ))}
                </>
              ))}
              {/*
              
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                        fontWeight: 600,
                      }}
                    >
                      Operatörden Gelen Duruşlar
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    pr: 0.5,
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {operatorsErrorSum()} dk
                </Typography>
              </Box> */}
              {message.Line.ErrorsFromOperator?.map((subItem, subIndex) => (
                <Box
                  key={subIndex}
                  sx={{
                    width: "calc(100% - 36px)",
                    display: "flex",
                    flexDirection: "column",
                    py: 0.5,
                    ml: 4,
                    gap: 0.5,
                    borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: { xs: "center", sm: "space-between" },
                      flexWrap: "wrap",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 0.66,
                        minWidth: { xs: "100%", sm: "200px" },
                        position: "relative",
                        display: "flex",
                        justifyContent: { xs: "center", sm: "flex-start" },
                        gap: 1.5,
                      }}
                    >
                      <Typography
                        sx={{
                          color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                        }}
                      >
                        {subItem.title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 0.18,
                        minWidth: { xs: "100%", sm: "128px" },
                        display: "flex",
                        justifyContent: { xs: "center", sm: "flex-start" },
                        alignItems: "center",
                        color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                        border: `1px dashed rgba(${returnErrorColor(MachineType.GENERAL)}, 0.81)`,
                        background: `rgba(${returnErrorColor(MachineType.GENERAL)}, 0.21)`,
                        borderRadius: 1,
                        px: 1,
                        py: 0.5,
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TouchAppRounded
                          sx={{
                            fontSize: "1.1rem",
                            color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography>GENEL</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flex: 0.16,
                        minWidth: { xs: "100%", sm: "100px" },
                        display: "flex",
                        justifyContent: { xs: "center", sm: "flex-end" },
                        gap: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                          mr: 0.5,
                        }}
                      >
                        {generateTime(subItem.duration)}
                      </Typography>
                    </Box>
                  </Box>
                  {subItem.description && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <InfoTwoTone
                        sx={{
                          fontSize: "1rem",
                          color: "#d0d3d4",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#f0f3f4",
                          fontSize: "0.81rem",
                        }}
                      >
                        {subItem.description}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
              {message.Machines.map((item) => (
                <>
                  {item.ErrorsFromOperator?.map((subItem, subIndex) => (
                    <Box
                      key={subIndex}
                      sx={{
                        width: "calc(100% - 36px)",
                        display: "flex",
                        flexDirection: "column",
                        py: 0.5,
                        ml: 4,
                        gap: 0.5,
                        borderBottom: "1px solid rgba(255,255,255, 0.13)",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: { xs: "center", sm: "space-between" },
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            flex: 0.66,
                            minWidth: { xs: "100%", sm: "200px" },
                            position: "relative",
                            display: "flex",
                            justifyContent: { xs: "center", sm: "flex-start" },
                            gap: 1.5,
                          }}
                        >
                          <Typography
                            sx={{
                              color: `rgb(${returnErrorColor(item.machine_type)})`,
                            }}
                          >
                            {subItem.title}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            flex: 0.18,
                            minWidth: { xs: "100%", sm: "128px" },
                            display: "flex",
                            justifyContent: { xs: "center", sm: "flex-start" },
                            alignItems: "center",
                            color: `rgb(${returnErrorColor(item.machine_type)})`,
                            border: `1px dashed rgba(${returnErrorColor(item.machine_type)}, 0.81)`,
                            background: `rgba(${returnErrorColor(item.machine_type)}, 0.21)`,
                            borderRadius: 1,
                            px: 1,
                            py: 0.5,
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TouchAppRounded
                              sx={{
                                fontSize: "1.1rem",
                                color: `rgb(${returnErrorColor(item.machine_type)})`,
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography>{item.machine_type}</Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            flex: 0.16,
                            minWidth: { xs: "100%", sm: "100px" },
                            display: "flex",
                            justifyContent: { xs: "center", sm: "flex-end" },
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              color: `rgb(${returnErrorColor(item.machine_type)})`,
                              mr: 0.5,
                            }}
                          >
                            {generateTime(subItem.duration)}
                          </Typography>
                        </Box>
                      </Box>
                      {subItem.description && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <InfoTwoTone
                            sx={{
                              fontSize: "1rem",
                              color: "#d0d3d4",
                            }}
                          />
                          <Typography
                            sx={{
                              color: "#f0f3f4",
                              fontSize: "0.81rem",
                            }}
                          >
                            {subItem.description}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ))}
                </>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#f0f3f4",
                  fontWeight: 700,
                  fontSize: "1.15rem",
                }}
              >
                Hesaplamalar
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundImage:
                  "linear-gradient(140deg, hsl(211deg 29% 25%) 0%, hsl(222deg 33% 18%) 59%, hsl(234deg 36% 11%) 100%)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#16A085",
                        fontSize: "1.25rem",
                        fontWeight: 700,
                      }}
                    >
                      Planlanan Üretim Süresi
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#16A085",
                        fontWeight: 600,
                      }}
                    >
                      =
                    </Typography>
                    <Typography
                      sx={{
                        color: "#d0ece7",
                        fontStyle: "italic",
                      }}
                    >
                      Vardiya Uzunluğu - Molalar
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: "rgb(22, 160, 133)",
                    fontWeight: 700,
                    fontSize: "1.25rem",
                  }}
                >
                  {message.Line.PlannedProductionTime} dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Vardiya Uzunluğu
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.ShiftLength} dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Molalar
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.Breaks} dk
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundImage:
                  "linear-gradient(140deg, hsl(211deg 29% 25%) 0%, hsl(222deg 33% 18%) 59%, hsl(234deg 36% 11%) 100%)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#AF7AC5",
                        fontSize: "1.25rem",
                        fontWeight: 700,
                      }}
                    >
                      Çalışma Süresi
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#AF7AC5",
                        fontWeight: 600,
                      }}
                    >
                      =
                    </Typography>
                    <Typography
                      sx={{
                        color: "#efe4f3",
                        fontStyle: "italic",
                      }}
                    >
                      Planlanan Üretim Süresi - Durma Süresi
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: "#AF7AC5",
                    fontSize: "1.25rem",
                    fontWeight: 700,
                  }}
                >
                  {message.Line.WorkingTime &&
                    (message.Line.WorkingTime / 60).toFixed(0)}{" "}
                  dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Planlanan Üretim Süresi
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.PlannedProductionTime} dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Durma Süresi (Planlanmış Duruşlar + Planlanmamış Duruşlar)
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.DownTime &&
                    (message.Line.DownTime / 60).toFixed(0)}{" "}
                  dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 48px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 6,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Planlanmamış Duruşlar (ör. Arızalar)
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.UnplannedStops &&
                    message.Line.UnplannedStops.toFixed(0)}{" "}
                  dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 48px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                  ml: 6,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Planlanmış Duruşlar (ör. Ayarlamalar)
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.PlannedStops} dk
                </Typography>
              </Box>
            </Box>

            {/*

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundImage:
                  "linear-gradient(140deg, hsl(211deg 29% 25%) 0%, hsl(222deg 33% 18%) 59%, hsl(234deg 36% 11%) 100%)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#4DD0E1",
                        fontSize: "1.25rem",
                        fontWeight: 700,
                      }}
                    >
                      İyi Sayım
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#4DD0E1",
                        fontWeight: 600,
                      }}
                    >
                      =
                    </Typography>

                    <Typography
                      sx={{
                        color: "#dbf6f9",
                        fontStyle: "italic",
                      }}
                    >
                      Toplam Üretilen Adet - Reddedilen Adet
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: "#4DD0E1",
                    fontSize: "1.25rem",
                    fontWeight: 700,
                  }}
                >
                  {message.Line.GoodCount} adet
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Toplam Üretilen Adet
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.VerifiedBottleTotalPieces} adet
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Reddedilen Adet
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.RejectTotalPieces} adet
                </Typography>
              </Box>
            </Box> */}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundImage:
                  "linear-gradient(140deg, hsl(225deg 15% 15%) 0%, hsl(229deg 25% 13%) 59%,hsl(234deg 36% 11%) 100%)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderTop: "1px solid rgba(255, 139, 0, 0.48)",
                borderLeft: "1px solid rgba(255, 139, 0, 0.48)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(255, 139, 0)",
                        fontSize: "1.25rem",
                        fontWeight: 700,
                      }}
                    >
                      Kullanılabilirlik
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(255, 139, 0)",
                        fontWeight: 600,
                      }}
                    >
                      =
                    </Typography>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        color: "#F6E2D7",
                      }}
                    >
                      Çalışma Süresi / Planlanan Üretim Süresi
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: "rgb(255, 139, 0)",
                    fontSize: "1.25rem",
                    fontWeight: 700,
                  }}
                >
                  {message.Line.Availability &&
                    message.Line.Availability.toFixed(1)}{" "}
                  %
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Çalışma Süresi
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.WorkingTime &&
                    (message.Line.WorkingTime / 60).toFixed(0)}{" "}
                  dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Planlanan Üretim Süresi
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.PlannedProductionTime} dk
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundImage:
                  "linear-gradient(140deg, hsl(225deg 15% 15%) 0%, hsl(229deg 25% 13%) 59%,hsl(234deg 36% 11%) 100%)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderTop: "1px solid rgba(253, 202, 64, 0.48)",
                borderLeft: "1px solid rgba(253, 202, 64, 0.48)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(253, 202, 64)",
                        fontSize: "1.25rem",
                        fontWeight: 700,
                      }}
                    >
                      Performans (Performance)
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(253, 202, 64)",
                        fontWeight: 600,
                      }}
                    >
                      =
                    </Typography>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        color: "#F5EEDD",
                      }}
                    >
                      (Toplam Üretilen Adet / (Planlanan Üretim Süresi -
                      Planlanmış Duruşlar)) / İdeal Çalışma Oranı
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: "rgb(253, 202, 64)",
                    fontSize: "1.25rem",
                    fontWeight: 700,
                  }}
                >
                  {message.Line.Performance &&
                    message.Line.Performance.toFixed(1)}{" "}
                  %
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Toplam Üretilen Adet
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.VerifiedBottleTotalPieces} adet
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Planlanan Üretim Süresi
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.PlannedProductionTime &&
                    message.Line.PlannedProductionTime.toFixed(0)}{" "}
                  dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Planlanmış Duruşlar
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.PlannedStops &&
                    message.Line.PlannedStops.toFixed(0)}{" "}
                  dk
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    İdeal Çalışma Oranı (Dakikada Üretilen Adet)
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.IdealRunRatePPM} adet/dk
                </Typography>
              </Box>
            </Box>

            {/* 

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundImage:
                  "linear-gradient(140deg, hsl(225deg 15% 15%) 0%, hsl(229deg 25% 13%) 59%,hsl(234deg 36% 11%) 100%)",
                border: "1px solid rgba(255,255,255, 0.26)",
                borderTop: "1px solid rgba(36, 109, 255, 0.48)",
                borderLeft: "1px solid rgba(36, 109, 255, 0.48)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(36, 109, 255)",
                        fontSize: "1.25rem",
                        fontWeight: 700,
                      }}
                    >
                      Kalite (Quality)
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(36, 109, 255)",
                        fontWeight: 600,
                      }}
                    >
                      =
                    </Typography>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        color: "#CFDCFF",
                      }}
                    >
                      İyi Sayım / Toplam Üretilen Adet
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: "rgb(36, 109, 255)",
                    fontSize: "1.25rem",
                    fontWeight: 700,
                  }}
                >
                  {message.Line.Quality && message.Line.Quality.toFixed(1)} %
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    İyi Sayım
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.GoodCount} adet
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Toplam Üretilen Adet
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.VerifiedBottleTotalPieces} adet
                </Typography>
              </Box>
            </Box> */}

            {/*

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundImage:
                  "linear-gradient(140deg, hsl(225deg 15% 15%) 0%, hsl(229deg 25% 13%) 59%,hsl(234deg 36% 11%) 100%)",
                border: "1px solid rgba(255,255,255, 0.24)",
                borderTop: "1px solid rgba(1, 173, 84, 0.48)",
                borderLeft: "1px solid rgba(1, 173, 84, 0.48)",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(1, 174, 84)",
                        fontSize: "1.25rem",
                        fontWeight: 700,
                      }}
                    >
                      Verim (OEE)
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(1, 174, 84)",
                        fontWeight: 600,
                      }}
                    >
                      =
                    </Typography>
                    <Typography
                      sx={{
                        color: "#C6E6DB",
                        fontStyle: "italic",
                      }}
                    >
                      Kullanılabilirlik x Performans x Kalite
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: "rgb(1, 174, 84)",
                    fontSize: "1.25rem",
                    fontWeight: 700,
                  }}
                >
                  {message.Line.OEE && message.Line.OEE.toFixed(1)} %
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Kullanılabilirlik
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.Availability &&
                    message.Line.Availability.toFixed(1)}{" "}
                  %
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Performans
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.Performance &&
                    message.Line.Performance.toFixed(1)}{" "}
                  %
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Kalite
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.Line.Quality && message.Line.Quality.toFixed(1)} %
                </Typography>
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default ReportsPage;

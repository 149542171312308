import React, { useEffect, useState } from "react";
import logo from "./../logo-white.png";
import type {} from "@mui/x-data-grid/themeAugmentation";

import {
  AppBar,
  Box,
  Container,
  Grow,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  PersonRounded,
  PlayCircleTwoTone,
  StopCircleTwoTone,
} from "@mui/icons-material";
import Moment from "react-moment";
import { i18n } from "dateformat";
import useWebSocket from "react-use-websocket";
import { Message, ScheduledStatus } from "../message";
import BreakChangeDialog from "../components/BreakChangeDialog";
import ShiftChangeDialog from "../components/ShiftChangeDialog";
import { useParams } from "react-router-dom";
import ReactCustomSwitch from "../components/slider-switch/SliderSwitch";
import ErrorChangeDialog from "../components/ErrorChangeDialog";
import CleaningSettingsMaintenanceSeriesChangeFirstDialog from "../components/CleaningSettingsMaintenanceSeriesChangeFirstDialog";

export type CleaningSettingsMaintenanceSeriesChangeInfoChange = {
  type: ScheduledStatus | null;
  isStarted: boolean;
  description: string;
};

export type ErrorInfoChange = {
  title: string;
  isStarted: boolean;
  machine: any;
  startedAt: number;
  duration: number;
  solvedAt: number;
  description?: string;
};

export type ShiftInfoChange = {
  value: boolean;
  operatorFullName: string | null;
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export const convertSecondToTime = (seconds: number) => {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
};

export const convertMinuteToTime = (minutes: number) => {
  let hours = Math.floor(minutes / 60);

  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:00`;
};

function InputsPage() {
  // Settingstemizlik
  i18n.dayNames = [
    "Paz",
    "Pzt",
    "Sal",
    "Çar",
    "Per",
    "Cum",
    "Cmt",
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];

  // ** States

  const [processTime, setProcessTime] = useState(0);

  const [message, setMessage] = useState<Message>({
    Machines: [],
    Line: {
      Availability: 0,
      Performance: 0,
      Quality: 0,
      OEE: 0,
      Breaks: 0,
      Breaks1: 0,
      Breaks2: 0,
      DolumBottleOutCounter: 0, // ** Dolan Şişe
      DolumTurnTableFull: false,
      DownTime: 0,
      GoodCount: 0,
      Red: false,
      Yellow: false,
      Green: false,
      IdealRunRatePPM: 0,
      InBottlePieces: 0, // ** Giren Şişe
      KutulamaBoxRejectCounter: 0, // ** Kutulama Fire
      PackageOutPieces: 0, // ** Çıkan Koli
      PlannedProductionTime: 0,
      PlannedStops: 0,
      PlannedStops1: 0,
      PlannedStops2: 0,
      PresentTime: 0,
      RecycleBottles: 0,
      RejectBottleTotalPieces: 0, // ** Etiket Fire
      RejectTotalPieces: 0,
      VerifiedBoxTotalPieces: 0, // ** Kutulanan Ürün
      UnfillableBottlePieces: 0, // ** Dolum Fire
      VerifiedBottleTotalPieces: 0, // ** Etiketli Ürün - Çıkan Ürün
      BreaksTime: 0,
      WorkingTime: 0,
      ShiftLength: 0,
      UnplannedStops: 0,
      ErrorsFromOperator: [],
      StartProduction: false,
      StartBreaks: false,
      StartScheduledStops: false,
    },
    Time: 0,
    Connected: false,
  });

  let { accessToken } = useParams();

  const [timeString, setTimeString] = useState<Date>(new Date());
  const [firstInitialized, setFirstInitialized] = useState<boolean>(false);

  const [shiftChangeDialogState, setShiftChangeDialogState] =
    useState<boolean>(false);

  const [
    cleaningAndMaintenanceChangeDialogState,
    setCleaningAndMaintenanceChangeDialogState,
  ] = useState<boolean>(false);

  const [breakChangeDialogState, setBreakChangeDialogState] =
    useState<boolean>(false);

  const [errorChangeDialogState, setErrorChangeDialogState] =
    useState<boolean>(false);

  const [shiftInfo, setShiftInfo] = useState<ShiftInfoChange>({
    value: false,
    operatorFullName: null,
  });

  const [breakInfo, setBreakInfo] = useState<boolean>(false);

  const [
    cleaningSettingsMaintenanceSeriesChangeInfo,
    setCleaningSettingsMaintenanceSeriesChangeInfo,
  ] = useState<CleaningSettingsMaintenanceSeriesChangeInfoChange>({
    isStarted: false,
    type: null,
    description: "",
  });

  const [errorInfo, setErrorInfo] = useState<ErrorInfoChange>({
    title: "",
    isStarted: false,
    machine: "",
    startedAt: Date.now(),
    duration: 0,
    solvedAt: Date.now(),
    description: undefined,
  });

  useEffect(() => {
    if (errorInfo.isStarted && !errorChangeDialogState) {
      //Implementing the setInterval method
      const intervalInfo = setInterval(() => {
        const dateInMillisecs = Date.now();
        const dateInSecs = Math.round(dateInMillisecs / 1000);
        const dateInSecsForStarted = Math.round(errorInfo.startedAt / 1000);

        setErrorInfo({
          ...errorInfo,
          duration: dateInSecs - dateInSecsForStarted,
        });
      }, 1000);

      //Clearing the interval
      return () => clearInterval(intervalInfo);
    }
  }, [errorInfo, errorChangeDialogState]);

  const handleShiftChange = (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => {
    setShiftChangeDialogState(true);
  };

  const handleCleaningAndMaintenanceInfoChange = (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => {
    setCleaningAndMaintenanceChangeDialogState(true);
  };

  const handleBreakChange = (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => {
    setBreakChangeDialogState(true);
  };

  const handleErrorChange = (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => {
    setErrorChangeDialogState(true);
  };

  useEffect(() => {
    console.log(
      "title:",
      message.Line.PersonelErrorTitle as string,
      "isStarted:",
      message.Line.PersonelErrorStatus,
      "machine",
      message.Line.PersonelErrorMachineType,
      "startedAt",
      message.Line.PersonelErrorTime as number,
      "duration:",
      Math.round(Date.now() / 1000 - Number(message.Line.PersonelErrorTime)),
      "solvedAt",
      Date.now(),
      "description",
      message.Line.PersonelErrorDescription
    );
    if (message.Line.PersonelErrorStatus) {
      setFirstInitialized(true);
      setErrorInfo({
        title: message.Line.PersonelErrorTitle as string,
        isStarted: message.Line.PersonelErrorStatus,
        machine: message.Line.PersonelErrorMachineType,
        startedAt: Number(message.Line.PersonelErrorTime) * 1000,
        duration: Math.round(
          (Date.now() / 1000) - Number(message.Line.PersonelErrorTime)
        ),
        solvedAt: Date.now(),
        description: message.Line.PersonelErrorDescription,
      });
    }
  }, [message.Line.PersonelErrorStatus]);

  // ** UseEffects
  useEffect(() => {
    setTimeString(new Date(Number(processTime) * 1000));
  }, [processTime]);

  const WS_URL = process.env.REACT_APP_WEBSOCKET_HOST || "";

  const { lastMessage } = useWebSocket(WS_URL, {
    onOpen: () => true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    try {
      lastMessage && setMessage(JSON.parse(lastMessage.data));
      lastMessage && setProcessTime(JSON.parse(lastMessage.data).Time);
    } catch {}
  }, [lastMessage]);

  useEffect(() => {
    setShiftInfo({
      value: message.Line.StartProduction,
      operatorFullName: message.operator_name ?? null,
    });
  }, [message.Line.StartProduction, message.operator_name]);

  useEffect(() => {
    setCleaningSettingsMaintenanceSeriesChangeInfo({
      ...cleaningSettingsMaintenanceSeriesChangeInfo,
      isStarted: message.Line.StartScheduledStops,
    });
  }, [message.Line.StartScheduledStops]);

  useEffect(() => {
    setBreakInfo(message.Line.StartBreaks);
  }, [message.Line.StartBreaks]);

  return (
    <div
      style={{
        position: "relative",
        display: "block",
        width: "100dvw !important",
        height: "100dvh",
      }}
    >
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          background: "transparent",
          boxShadow: "none",
          backdropFilter: { xs: "blur(10px)", md: "none" },
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: { xs: "center", sm: "space-between" },
              flexWrap: "wrap",
              alignItems: "center",
              alignContent: "center",
              mt: 3.5,
              mb: 2,
              ml: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "column",
                backdropFilter: "blur(10px)",
                borderRadius: 3,
                px: 2,
                py: 1,
              }}
            >
              <img
                src={logo}
                style={{
                  height: 56,
                }}
                alt="logo"
              />
              <Typography
                sx={{
                  color: "#FBFCFC !important",
                  fontWeight: 400,
                  fontSize: "0.85rem",
                  pr: 1,
                }}
              >
                Coding For Life
              </Typography>
            </Box>
            <Box>
              <Moment
                style={{
                  fontSize: "1.75rem",
                  fontWeight: 600,
                  color: "#f0f3f4",
                }}
                className="desc"
                format="DD-MM-yyyy HH:mm:ss"
              >
                {Date.now()}
              </Moment>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                borderRadius: 2,
                backdropFilter: "blur(16px)",
                flexWrap: "wrap",
                gap: 0.8,
                background: "rgba(255, 255, 255, 0.07)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1.5,
                  pl: 0.8,
                  pr: 1.5,
                  py: 0.8,
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "cneter",
                    alignItems: "center",
                    background: "rgba(240, 243, 244, 0.21)",
                    borderRadius: 1,
                    borderBottom: "2px solid rgba(240, 243, 244, 0.81)",
                  }}
                >
                  <PersonRounded
                    fontSize="large"
                    sx={{
                      m: 0.5,
                      color: "#f0f3f4",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: "1.45rem",
                    color: "#f0f3f4",
                  }}
                >
                  {message.operator_name ?? "Operatör Seçilmedi"}
                </Typography>
              </Box>
              <Box
                sx={{
                  position: "relative",
                  minWidth: 128,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 2,
                  maxHeight: 42,
                  backdropFilter: "blur(36px)",
                  backgroundColor: `rgba(${
                    message !== undefined && message.Connected === false
                      ? "255, 76, 81"
                      : "2, 224, 164"
                  }  , 0.21)`,
                  color: `rgb(${
                    message !== undefined && message.Connected === false
                      ? "255, 76, 81"
                      : "2, 224, 164"
                  })`,
                  border: `1px solid rgb(${
                    message !== undefined && message.Connected === false
                      ? "255, 76, 81"
                      : "2, 224, 164"
                  })`,
                }}
              >
                <Box mb={1} ml={1}>
                  <span className="heartbeat"></span>
                  <span className="dot"></span>
                </Box>
                <Box
                  sx={{
                    fontSize: "1.25rem",
                  }}
                >
                  <Moment className="desc" format="HH:mm:ss">
                    {timeString}
                  </Moment>
                </Box>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Container
        disableGutters
        maxWidth={"xl"}
        sx={{
          mt: { xs: "255px", sm: "156px" },
          height: { xs: "100%", md: "100dvh" },
          maxHeight: { xs: "calc(100dvh - 255px)", sm: "calc(100dvh - 156px)" },
          overflowY: "scroll",
        }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          alignItems="center"
          sx={{
            gap: 2,
          }}
        >
          <Box
            sx={{
              flex: 1,
              width: { xs: "90%", sm: "85%" },
              margin: 1.5,
              padding: 0,
              paddingLeft: 3,
              paddingRight: 3,
              borderRadius: 12,
              borderLeft: "11px solid rgb(2, 224, 164)",
              background: "rgba(2, 224, 164, 0.21)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignItems: "center",
                height: "100%",
                p: 1,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-start" },
                  alignItems: "center",
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    p: 0,
                    mr: 3,
                    display: "flex",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(2, 224, 164)",
                  }}
                >
                  {shiftInfo.value ? (
                    <StopCircleTwoTone
                      sx={{
                        fontSize: "calc(1.1rem + 6.1vh)",
                      }}
                    />
                  ) : (
                    <PlayCircleTwoTone
                      sx={{
                        fontSize: "calc(1.1rem + 6.1vh)",
                      }}
                    />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "calc(1.1rem + 1.7vh)",
                    color: "rgb(2, 224, 164)",
                  }}
                >
                  Vardiya
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
                  gap: 3,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {shiftInfo.value && (
                  <Grow
                    in={shiftInfo.value}
                    style={{
                      transformOrigin: "10 0 0",
                    }}
                    timeout={1000}
                  >
                    <Box
                      sx={{
                        display: shiftInfo.value ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 1,
                        px: 2,
                        borderRadius: 2.5,
                        height: "100%",
                        background: "rgba(0,0,0, 0.66)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "monospace",
                          fontWeight: 700,
                          fontSize: "calc(1.1rem + 1.7vh)",
                          color: "#FBFCFC",
                        }}
                      >
                        {convertSecondToTime(message.Line.PresentTime)}
                      </Typography>
                    </Box>
                  </Grow>
                )}

                <ReactCustomSwitch
                  width={256}
                  height={96}
                  onChange={handleShiftChange}
                  checked={shiftInfo.value}
                  disabled={
                    shiftInfo.value &&
                    (breakInfo ||
                      cleaningSettingsMaintenanceSeriesChangeInfo.isStarted ||
                      errorInfo.isStarted)
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              width: { xs: "90%", sm: "85%" },
              margin: 1.5,
              padding: 0,
              paddingLeft: 3,
              paddingRight: 3,
              borderRadius: 12,
              borderLeft: "11px solid rgb(236, 240, 241)",
              background: "rgba(236, 240, 241, 0.21)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignItems: "center",
                height: "100%",
                p: 1,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-start" },
                  alignItems: "center",
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    p: 0,
                    mr: 3,
                    display: "flex",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(236, 240, 241)",
                  }}
                >
                  {cleaningSettingsMaintenanceSeriesChangeInfo.isStarted ? (
                    <StopCircleTwoTone
                      sx={{
                        fontSize: "calc(1.1rem + 6.1vh)",
                      }}
                    />
                  ) : (
                    <PlayCircleTwoTone
                      sx={{
                        fontSize: "calc(1.1rem + 6.1vh)",
                      }}
                    />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "calc(1.1rem + 1.7vh)",
                    color: "rgb(236, 240, 241)",
                  }}
                >
                  Temizlik, Ayar, Bakım, Seri Değişimi
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
                  gap: 3,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {cleaningSettingsMaintenanceSeriesChangeInfo.isStarted && (
                  <Grow
                    in={cleaningSettingsMaintenanceSeriesChangeInfo.isStarted}
                    style={{
                      transformOrigin: "10 0 0",
                    }}
                    timeout={1000}
                  >
                    <Box
                      sx={{
                        display:
                          cleaningSettingsMaintenanceSeriesChangeInfo.isStarted
                            ? "flex"
                            : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 1,
                        px: 2,
                        borderRadius: 2.5,
                        height: "100%",
                        background: "rgba(0,0,0, 0.66)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "monospace",
                          fontWeight: 700,
                          fontSize: "calc(1.1rem + 1.7vh)",
                          color: "#FBFCFC",
                        }}
                      >
                        {convertMinuteToTime(message.Line.PlannedStops1)}
                      </Typography>
                    </Box>
                  </Grow>
                )}
                <ReactCustomSwitch
                  width={256}
                  height={96}
                  onChange={handleCleaningAndMaintenanceInfoChange}
                  checked={
                    cleaningSettingsMaintenanceSeriesChangeInfo.isStarted
                  }
                  disabled={
                    !shiftInfo.value || breakInfo || errorInfo.isStarted
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              width: { xs: "90%", sm: "85%" },
              margin: 1.5,
              padding: 0,
              paddingLeft: 3,
              paddingRight: 3,
              borderRadius: 12,
              borderLeft: "11px solid rgb(243, 156, 18)",
              background: "rgba(243, 156, 18, 0.21)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                p: 1,
                height: "100%",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-start" },
                  alignItems: "center",
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    p: 0,
                    mr: 3,
                    display: "flex",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(243, 156, 18)",
                  }}
                >
                  {breakInfo ? (
                    <StopCircleTwoTone
                      sx={{
                        fontSize: "calc(1.1rem + 6.1vh)",
                      }}
                    />
                  ) : (
                    <PlayCircleTwoTone
                      sx={{
                        fontSize: "calc(1.1rem + 6.1vh)",
                      }}
                    />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "calc(1.1rem + 1.7vh)",
                    color: "rgb(243, 156, 18)",
                  }}
                >
                  Mola
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
                  gap: 3,
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {breakInfo && (
                  <Grow
                    in={breakInfo}
                    style={{
                      transformOrigin: "10 0 0",
                    }}
                    timeout={1000}
                  >
                    <Box
                      sx={{
                        display: breakInfo ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 1,
                        px: 2,
                        borderRadius: 2.5,
                        height: "100%",
                        background: "rgba(0,0,0, 0.66)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "monospace",
                          fontWeight: 700,
                          fontSize: "calc(1.1rem + 1.7vh)",
                          color: "#FBFCFC",
                        }}
                      >
                        {convertSecondToTime(message.Line.BreaksTime)}
                      </Typography>
                    </Box>
                  </Grow>
                )}
                <ReactCustomSwitch
                  width={256}
                  height={96}
                  onChange={handleBreakChange}
                  checked={breakInfo}
                  disabled={
                    !shiftInfo.value ||
                    cleaningSettingsMaintenanceSeriesChangeInfo.isStarted ||
                    errorInfo.isStarted
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              width: { xs: "90%", sm: "85%" },
              margin: 1.5,
              padding: 0,
              paddingLeft: 3,
              paddingRight: 3,
              borderRadius: 12,
              borderLeft: "11px solid rgb(255, 76, 81)",
              background: "rgba(255, 76, 81, 0.21)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                padding: 1,
                height: "100%",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-start" },
                  alignItems: "center",
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    p: 0,
                    mr: 3,
                    display: "flex",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(255, 76, 81)",
                  }}
                >
                  {errorInfo.isStarted ? (
                    <StopCircleTwoTone
                      sx={{
                        fontSize: "calc(1.1rem + 6.1vh)",
                      }}
                    />
                  ) : (
                    <PlayCircleTwoTone
                      sx={{
                        fontSize: "calc(1.1rem + 6.1vh)",
                      }}
                    />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "calc(1.1rem + 1.7vh)",
                    color: "rgb(255, 76, 81)",
                  }}
                >
                  Duruşlar
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
                  gap: 3,
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {errorInfo.isStarted && (
                  <Grow
                    in={errorInfo.isStarted}
                    style={{
                      transformOrigin: "10 0 0",
                    }}
                    timeout={1000}
                  >
                    <Box
                      sx={{
                        display: errorInfo.isStarted ? "flex" : "none",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        py: 1,
                        px: 2,
                        borderRadius: 2.5,
                        background: "rgba(0,0,0, 0.66)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "monospace",
                          fontWeight: 700,
                          fontSize: "calc(1.1rem + 1.7vh)",
                          color: "#FBFCFC",
                        }}
                      >
                        {convertSecondToTime(errorInfo.duration)}
                      </Typography>
                    </Box>
                  </Grow>
                )}
                <ReactCustomSwitch
                  width={256}
                  height={96}
                  onChange={handleErrorChange}
                  checked={errorInfo.isStarted}
                  disabled={
                    !shiftInfo.value ||
                    cleaningSettingsMaintenanceSeriesChangeInfo.isStarted ||
                    breakInfo
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <ShiftChangeDialog
          accessToken={accessToken as string}
          shiftInfo={shiftInfo}
          setShiftInfo={setShiftInfo}
          dialogState={shiftChangeDialogState}
          setDialogState={setShiftChangeDialogState}
        />
        <CleaningSettingsMaintenanceSeriesChangeFirstDialog
          accessToken={accessToken as string}
          cleaningSettingsMaintenanceSeriesChangeInfo={
            cleaningSettingsMaintenanceSeriesChangeInfo
          }
          setCleaningSettingsMaintenanceSeriesChangeInfo={
            setCleaningSettingsMaintenanceSeriesChangeInfo
          }
          dialogState={cleaningAndMaintenanceChangeDialogState}
          setDialogState={setCleaningAndMaintenanceChangeDialogState}
        />
        <BreakChangeDialog
          accessToken={accessToken as string}
          breakInfo={breakInfo}
          setBreakInfo={setBreakInfo}
          dialogState={breakChangeDialogState}
          setDialogState={setBreakChangeDialogState}
        />
        <ErrorChangeDialog
          accessToken={accessToken as string}
          errorInfo={errorInfo}
          setErrorInfo={setErrorInfo}
          firstInitialized={firstInitialized}
          setFirstInitialized={setFirstInitialized}
          dialogState={errorChangeDialogState}
          setDialogState={setErrorChangeDialogState}
        />
      </Container>
    </div>
  );
}

export default InputsPage;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./App.css";
import logo from "./logo-white.png";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { useKeyPress } from "@react-typed-hooks/use-key-press";

import {
  AppBar,
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AutoGraphRounded,
  CallSplitRounded,
  OpenInNewRounded,
  PersonRounded,
  TodayRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import Moment from "react-moment";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import dateFormat from "dateformat";
import { i18n } from "dateformat";
import DataGridSpeacial from "./components/DataGridSpecial";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import ShiftHourInfoDialog from "./components/ShiftHourInfoDialog";
import MainAnalytics from "./components/MainAnalytics";
import axios from "axios";
import { disableDebugMode, enableDebugMode } from "./global-states/debug-mode";
import DebugModeInfo from "./components/DebugModeInfo";
import useWebSocket from "react-use-websocket";
import { Message } from "./message";
import ProductionFlowStateDialog from "./components/ProductionFlowStateDialog";
import OperatorCustomizedFilterButton from "./components/OperatorCustomizedFilterButton";
import DateCustomizedFilterButton from "./components/DateCustomizedFilterButton";
import { addDays } from "date-fns";
import Filters from "./components/Filters";

type SerieDataType = {
  x: string;
  y: number;
};

export enum ShiftType {
  shift = "shift",
  virtual_shift = "virtual-shift",
}

export type VirtualShiftFilter = {
  operators: string[];
  started_at: string | undefined;
  finished_at: string | undefined;
  page: number;
};

interface Range {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  color?: string | undefined;
  key?: string | undefined;
  autoFocus?: boolean | undefined;
  disabled?: boolean | undefined;
  showDateDisplay?: boolean | undefined;
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export const convertMsToTime = (seconds: number) => {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
};

function App() {
  // Settings
  i18n.dayNames = [
    "Paz",
    "Pzt",
    "Sal",
    "Çar",
    "Per",
    "Cum",
    "Cmt",
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];

  // ** Columns
  const customizedColumns = useMemo<GridColDef[]>(
    () => [
      {
        flex: 0.07,
        field: "Time",
        headerName: "Tarih",
        disableColumnMenu: false,
        minWidth: 156,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {dateFormat(row.Time * 1000, "dd.mm.yyyy, dddd")}
            </Typography>
          );
        },
      },
      {
        flex: 0.07,
        field: "Text",
        headerName: "Vardiya Saatleri",
        align: "center",
        headerAlign: "center",
        minWidth: 128,
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {row.Text}
            </Typography>
          );
        },
      },
      {
        flex: 0.07,
        field: "operator_name",
        headerName: "Operatör",
        align: "center",
        headerAlign: "center",
        minWidth: 42,
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {row.operator_name}
            </Typography>
          );
        },
      },
      {
        flex: 0.07,
        field: "DownTime",
        headerName: "Duruş Süresi",
        align: "center",
        headerAlign: "center",
        minWidth: 128,
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {convertMsToTime(row.Line.DownTime)}
            </Typography>
          );
        },
      },
      {
        flex: 0.07,
        field: "WorkingTime",
        headerName: "Çalışma Süresi",
        align: "center",
        headerAlign: "center",
        minWidth: 128,
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {convertMsToTime(row.Line.WorkingTime)}
            </Typography>
          );
        },
      },
      {
        flex: 0.07,
        field: "VerifiedBottleTotalPieces",
        headerName: "Çıkan Ürün",
        align: "center",
        headerAlign: "center",
        minWidth: 96,
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {row.Line.VerifiedBottleTotalPieces}
            </Typography>
          );
        },
      },
      {
        flex: 0.02,
        field: "on_basic_detail",
        headerName: "",
        align: "right",
        headerAlign: "right",
        minWidth: 42,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;
          return (
            <Tooltip title="İncele" placement="left">
              <IconButton
                sx={{
                  width: 32,
                  height: 32,
                  marginRight: 0.1,
                  borderRadius: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgba(255, 255, 255, 0.24)",
                }}
                onClick={() => {
                  onOpenMachineDetail(row);
                }}
              >
                <VisibilityRounded
                  fontSize="small"
                  sx={{
                    color: "#f0f3f4 !important",
                  }}
                />
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        flex: 0.02,
        field: "on_detail",
        headerName: "",
        align: "right",
        headerAlign: "right",
        minWidth: 36,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;
          return (
            <Tooltip title="Detaylı İncele" placement="right">
              <IconButton
                sx={{
                  width: 32,
                  height: 32,
                  marginRight: 0.1,
                  borderRadius: 1,
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                }}
                onClick={() => {
                  onOpenBlankShiftDetailsPage(row);
                }}
              >
                <OpenInNewRounded
                  fontSize="small"
                  sx={{
                    color: "#f0f3f4 !important",
                  }}
                />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  // ** Hooks
  const caps = useKeyPress({ targetKey: "CapsLock" });

  useEffect(() => {
    if (caps) {
      enableDebugMode();
    } else {
      disableDebugMode();
    }
  }, [caps]);

  // ** States
  const [dateRangeFilter, setDateRangeFilter] = useState<Range[]>([
    {
      startDate: addDays(new Date(), -10),
      endDate: new Date(),
      key: "selection",
      color: "#191919",
    },
  ]);
  const [filterOperators, setFilterOperators] = useState<string[]>([]);
  const [totalProductCount, setTotalProductCount] = useState<number>(0);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [processTime, setProcessTime] = useState(0);

  const [message, setMessage] = useState<Message>({
    Machines: [],
    Line: {
      Availability: 0,
      Performance: 0,
      Quality: 0,
      OEE: 0,
      Breaks: 0,
      Breaks1: 0,
      Breaks2: 0,
      DolumBottleOutCounter: 0, // ** Dolan Şişe
      DolumTurnTableFull: false,
      DownTime: 0,
      GoodCount: 0,
      Red: false,
      Yellow: false,
      Green: false,
      IdealRunRatePPM: 0,
      InBottlePieces: 0, // ** Giren Şişe
      KutulamaBoxRejectCounter: 0, // ** Kutulama Fire
      PackageOutPieces: 0, // ** Çıkan Koli
      PlannedProductionTime: 0,
      PlannedStops: 0,
      PlannedStops1: 0,
      PlannedStops2: 0,
      PresentTime: 0,
      RecycleBottles: 0,
      RejectBottleTotalPieces: 0, // ** Etiket Fire
      RejectTotalPieces: 0,
      VerifiedBoxTotalPieces: 0, // ** Kutulanan Ürün
      UnfillableBottlePieces: 0, // ** Dolum Fire
      VerifiedBottleTotalPieces: 0, // ** Etiketli Ürün - Çıkan Ürün
      BreaksTime: 0,
      WorkingTime: 0,
      ShiftLength: 0,
      UnplannedStops: 0,
      ErrorsFromOperator: [],
      StartProduction: false,
      StartBreaks: false,
      StartScheduledStops: false,
    },
    Time: 0,
    Connected: false,
  });

  const [rows, setRows] = useState<Message[]>([]);
  const [timeString, setTimeString] = useState<Date>(new Date());
  const [rowCountState, setRowCountState] = useState(0);

  const [serieDatasForStopTime, setSerieDatasForStopTime] = useState<
    SerieDataType[]
  >([]);
  const [serieDatasForWorkingTime, setSerieDatasForWorkingTime] = useState<
    SerieDataType[]
  >([]);

  const [serieDatasForProduct, setSerieDatasForProduct] = useState<
    SerieDataType[]
  >([]);
  const [serieDatasForOEE, setSerieDatasForOEE] = useState<SerieDataType[]>([]);

  const [serieDatasForAvailability, setSerieDatasForAvailability] = useState<
    SerieDataType[]
  >([]);
  const [serieDatasForPerformance, setSerieDatasForPerformace] = useState<
    SerieDataType[]
  >([]);

  const [productionFlowStateDialogState, setProductionFlowStateDialogState] =
    useState<boolean>(false);

  const [selectedShiftHourData, setSelectedShiftHourData] = useState<Message>({
    id: "",
    Line: {
      Availability: 0,
      Performance: 0,
      Quality: 0,
      OEE: 0,
      Breaks: 0,
      Breaks1: 0,
      Breaks2: 0,
      DolumBottleOutCounter: 0, // ** Dolan Şişe
      DolumTurnTableFull: false,
      DownTime: 0,
      GoodCount: 0,
      Red: false,
      Yellow: false,
      Green: false,
      IdealRunRatePPM: 0,
      InBottlePieces: 0, // ** Giren Şişe
      KutulamaBoxRejectCounter: 0, // ** Kutulama Fire
      PackageOutPieces: 0, // ** Çıkan Koli
      PlannedProductionTime: 0,
      PlannedStops: 0,
      PlannedStops1: 0,
      PlannedStops2: 0,
      PresentTime: 0,
      RecycleBottles: 0,
      RejectBottleTotalPieces: 0, // ** Etiket Fire
      RejectTotalPieces: 0,
      VerifiedBoxTotalPieces: 0, // ** Kutulanan Ürün
      UnfillableBottlePieces: 0, // ** Dolum Fire
      VerifiedBottleTotalPieces: 0, // ** Etiketli Ürün - Çıkan Ürün
      BreaksTime: 0,
      WorkingTime: 0,
      ShiftLength: 0,
      UnplannedStops: 0,
      ErrorsFromOperator: [],
      StartProduction: false,
      StartBreaks: false,
      StartScheduledStops: false,
    },
    Time: 0,
    Connected: false,
    Machines: [],
  });

  const [shiftHourInfoDialogState, setShiftHourInfoDialogState] =
    useState<boolean>(false);

  // ** Variables
  const series = [
    {
      name: "Duruş Süresi",
      data: serieDatasForStopTime,
    },
    {
      name: "Çalışma Süresi",
      data: serieDatasForWorkingTime,
    },
  ];

  const seriesByProduct = [
    {
      name: "Çıkan Ürün",
      data: serieDatasForProduct,
    },
  ];

  // ** Variables
  const seriesByOEE = [
    {
      name: "Verim",
      data: serieDatasForOEE,
    },
  ];

  const seriesByAvailabilityAndPerformance = [
    {
      name: "Kullanılabilirlik",
      data: serieDatasForAvailability,
    },
    {
      name: "Performans",
      data: serieDatasForPerformance,
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: "workingAndStopTime",
      type: "line",
      height: "100%",
      background: "transparent",
      toolbar: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        formatter(value: number) {
          return convertMsToTime(value);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#70E3C4",
        fontSize: "18px",
      },
    },
    colors: ["#ff3822", "#06ff59"],
    stroke: {
      curve: "straight",
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  const optionsByProduct: ApexOptions = {
    chart: {
      id: "productGraphic",
      type: "line",
      height: "100%",
      background: "transparent",
      toolbar: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        formatter(value: number) {
          return `${value} adet`;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#70E3C4",
        fontSize: "18px",
      },
    },
    colors: ["#06ff59"],
    stroke: {
      curve: "straight",
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  const optionsByOEE: ApexOptions = {
    chart: {
      id: "OEEGraphic",
      type: "line",
      height: "100%",
      background: "transparent",
      toolbar: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        formatter(value: number) {
          return `${value} %`;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#70E3C4",
        fontSize: "18px",
      },
    },
    colors: ["#16a085"],
    stroke: {
      curve: "straight",
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  const optionsByAvailabilityAndPerformance: ApexOptions = {
    chart: {
      id: "OEEGraphic1",
      type: "bar",
      height: "100%",
      background: "transparent",
      toolbar: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        formatter(value: number) {
          return `${value} %`;
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetY: 50,
      style: {
        fontSize: "9px",
        colors: ["#fff"],
      },
    },
    markers: {
      size: 5,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#70E3C4",
        fontSize: "18px",
      },
    },
    colors: ["#2ecc71", "#8e44ad"],
    stroke: {
      curve: "straight",
    },
    legend: {
      show: true,
      position: "top",
      showForSingleSeries: true,
      customLegendItems: ["Kullanılabilirlik", "Performans"],
      markers: {
        fillColors: ["#2ecc71", "#8e44ad"],
      },
    },
  };

  // ** Functions
  const onDetail = () => {};

  interface DataTableResult {
    data: Message[];
    count: number;
    productCount: number;
  }

  const getDatatableData = useCallback(async () => {
    const result = await axios.post<DataTableResult>(
      API_URL + "/getDatatable",
      {
        operators: filterOperators,
        started_at: dateRangeFilter[0].startDate?.toISOString(),
        finished_at: dateRangeFilter[0].endDate?.toISOString(),
        page: paginationModel.page,
      }
    );

    if (result.data) {
      localStorage.setItem(
        "maxShiftId",
        String(result.data.count > 0 && result.data.data[0].id)
      );
      setRowCountState(result.data.count);

      setRows(result.data.data);

      setTotalProductCount(result.data.productCount)


    }
  }, [dateRangeFilter, filterOperators, paginationModel]);

  const getGraphicData = useCallback(async () => {
    const rChart = await axios.get<any[]>(API_URL + "/lastListValue");

    if (rChart.data) {
      setSerieDatasForAvailability(
        rChart.data.map((item) => ({
          x: item.label,
          y: parseFloat(Number(item.availability).toFixed(2)),
        }))
      );
      setSerieDatasForPerformace(
        rChart.data.map((item) => ({
          x: item.label,
          y: parseFloat(Number(item.performance).toFixed(2)),
        }))
      );
      setSerieDatasForProduct(
        rChart.data.map((item) => ({ x: item.label, y: item.productValue }))
      );
    }
  }, []);

  const utf8_to_b64 = (str: string) => btoa(unescape(encodeURIComponent(str)));

  const onCreateVirtualShift = () => {
    const filters: VirtualShiftFilter = {
      operators: filterOperators,
      started_at: dateRangeFilter[0].startDate?.toISOString(),
      finished_at: dateRangeFilter[0].endDate?.toISOString(),
      page: paginationModel.page,
    };

    const encoded = utf8_to_b64(JSON.stringify(filters));

    window.open(
      `/shift-details/${ShiftType.virtual_shift}/${encoded}`,
      "_blank"
    );
  };

  const onOpenMachineDetail = (row: Message) => {
    setSelectedShiftHourData(row);
    setShiftHourInfoDialogState(true);
  };

  const onOpenBlankShiftDetailsPage = (row: Message) => {
    window.open(`/shift-details/${ShiftType.shift}/${row.id}`, "_blank");
  };

  const onOpenBlankDetailedStoppingAnalysisPage = () => {
    window.open(`/detailed-stopping-analysis`, "_blank");
  };

  const onOpenBlankYesterdayShiftDetailsPage = () => {
    const shiftId = rows.length > 0 && rows[0].id;
    window.open(`/shift-details/${ShiftType.shift}/${shiftId}`, "_blank");
  };

  const onOpenProductionFlowStateDialog = () => {
    setProductionFlowStateDialogState(true);
  };

  // ** UseEffects
  useEffect(() => {
    setTimeString(new Date(Number(processTime) * 1000));
  }, [processTime]);

  const WS_URL = process.env.REACT_APP_WEBSOCKET_HOST || "";
  const API_URL = process.env.REACT_APP_API_HOST || "";

  const { lastMessage } = useWebSocket(WS_URL, {
    onOpen: () => true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    try {
      lastMessage && setMessage(JSON.parse(lastMessage.data));
      lastMessage && setProcessTime(JSON.parse(lastMessage.data).Time);
    } catch {}
  }, [lastMessage]);

  useEffect(() => {
    getDatatableData();
  }, [getDatatableData]);

  useEffect(() => {
    getGraphicData();
  }, [getGraphicData]);

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          background: { xs: "transparent", md: "#1b1b1bcc" },
          backdropFilter: { xs: "blur(10px)", md: "none" },
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: { xs: "center", sm: "space-between" },
              flexWrap: "wrap",
              alignItems: "center",
              alignContent: "center",
              mt: 3.5,
              mb: 2,
              ml: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "column",
                backdropFilter: "blur(10px)",
                borderRadius: 3,
                px: 2,
                py: 1,
              }}
            >
              <img
                src={logo}
                style={{
                  height: 36,
                }}
                alt="logo"
              />
              <Typography
                sx={{
                  color: "#FBFCFC !important",
                  fontWeight: 400,
                  fontSize: "0.85rem",
                  pr: 1,
                }}
              >
                Coding For Life
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                borderRadius: 2,
                backdropFilter: "blur(16px)",
                flexWrap: "wrap",
                gap: 0.8,
              }}
            >
              {/* 

                                  <Tooltip title="Ana Gösterim" placement="bottom">
                  <IconButton
                    sx={{
                      width: 38,
                      height: 38,
                      marginRight: 1,
                      borderRadius: "50%",
                      backgroundColor:
                        TabEnumLayout.MAIN === tabLayout
                          ? "rgba(153, 163, 164, 0.13)"
                          : "rgba(4, 158, 230, 0.13)",
                      ":hover": {
                        backgroundColor:
                          TabEnumLayout.MAIN === tabLayout
                            ? "rgba(153, 163, 164, 0.13)"
                            : "rgba(4, 158, 230, 0.21)",
                      },
                      border:
                        TabEnumLayout.MAIN === tabLayout
                          ? "1px solid #99A3A4"
                          : "1px solid #049EE6",
                      backdropFilter: "blur(10px)",
                    }}
                    onClick={() => {
                      setTabLayout(TabEnumLayout.MAIN);
                    }}
                  >
                    <ArrowBackRounded
                      fontSize="small"
                      sx={{
                        color:
                          TabEnumLayout.MAIN === tabLayout
                            ? "#99A3A4 !important"
                            : "#049EE6 !important",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Kayıtlı Gösterim" placement="bottom">
                  <IconButton
                    sx={{
                      width: 38,
                      height: 38,
                      marginRight: 1,
                      borderRadius: "50%",
                      backgroundColor:
                        TabEnumLayout.SAVED === tabLayout
                          ? "rgba(153, 163, 164, 0.13)"
                          : "rgba(4, 158, 230, 0.13)",
                      ":hover": {
                        backgroundColor:
                          TabEnumLayout.SAVED === tabLayout
                            ? "rgba(153, 163, 164, 0.13)"
                            : "rgba(4, 158, 230, 0.21)",
                      },
                      border:
                        TabEnumLayout.SAVED === tabLayout
                          ? "1px solid #99A3A4"
                          : "1px solid #049EE6",
                      backdropFilter: "blur(10px)",
                    }}
                    onClick={() => {
                      setTabLayout(TabEnumLayout.SAVED);
                    }}
                  >
                    <ArrowForwardRounded
                      fontSize="small"
                      sx={{
                        color:
                          TabEnumLayout.SAVED === tabLayout
                            ? "#99A3A4 !important"
                            : "#049EE6 !important",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                  
                  */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1.5,
                  pl: 0.8,
                  pr: 1.5,
                  py: 0.8,
                  borderRadius: 2,
                  maxHeight: 42,
                  background: "rgba(240, 243, 244, 0.21)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "cneter",
                    alignItems: "center",
                    background: "rgba(240, 243, 244, 0.21)",
                    borderRadius: 1,
                    borderBottom: "2px solid rgba(240, 243, 244, 0.81)",
                  }}
                >
                  <PersonRounded
                    fontSize="small"
                    sx={{
                      m: 0.5,
                      color: "#f0f3f4",
                    }}
                  />
                </Box>

                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.operator_name ?? "Operatör Seçilmedi"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 0.8,
                  borderRadius: 2,
                  maxHeight: 42,
                  background: "rgba(255, 255, 255, 0.11)",
                }}
              >
                {/*
                
                <Tooltip title="Üretim Akış Durumu" placement="bottom">
                  <IconButton
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: 1,
                      ":hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.21)",
                      },
                    }}
                    onClick={() => {
                      onOpenProductionFlowStateDialog();
                    }}
                  >
                    <EarbudsRounded
                      fontSize="small"
                      sx={{
                        color: "#f0f3f4 !important",
                      }}
                    />
                  </IconButton>
                </Tooltip> */}
                <Tooltip title="Detaylı Duruş Analizi" placement="bottom">
                  <IconButton
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: 1,
                      ":hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.21)",
                      },
                    }}
                    onClick={() => {
                      onOpenBlankDetailedStoppingAnalysisPage();
                    }}
                  >
                    <CallSplitRounded
                      sx={{
                        color: "#f0f3f4 !important",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Bir Önceki Günün Vardiya Bilgileri"
                  placement="bottom"
                >
                  <IconButton
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: 1,
                      ":hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.21)",
                      },
                    }}
                    onClick={() => {
                      onOpenBlankYesterdayShiftDetailsPage();
                    }}
                  >
                    <TodayRounded
                      sx={{
                        color: "#f0f3f4 !important",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  position: "relative",
                  minWidth: 110,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 2,
                  maxHeight: 42,
                  backgroundColor: `rgba(${
                    message !== undefined && message.Connected === false
                      ? "255, 76, 81"
                      : "2, 224, 164"
                  }  , 0.21)`,
                  backdropFilter: "blur(10px)",
                  color: `rgb(${
                    message !== undefined && message.Connected === false
                      ? "255, 76, 81"
                      : "2, 224, 164"
                  })`,
                  border: `1px solid rgb(${
                    message !== undefined && message.Connected === false
                      ? "255, 76, 81"
                      : "2, 224, 164"
                  })`,
                }}
              >
                <Box mb={1} ml={1}>
                  <span className="heartbeat"></span>
                  <span className="dot"></span>
                </Box>
                <Box>
                  <DebugModeInfo label="Time" />
                  <Moment className="desc" format="HH:mm:ss">
                    {timeString}
                  </Moment>
                </Box>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component={"main"}
        sx={{
          width: "100dvw !important",
          height: "100dvh !important",
          overflow: "auto",
          position: "relative",
        }}
      >
        <MainAnalytics message={message} />
        <Container
          maxWidth={"xl"}
          sx={{
            p: "0 !important",
            marginTop: 15,
            paddingBottom: 5,
            px: { xs: 0, md: 5 },
            width: "100% !important",
          }}
        >
          {/*
            }
            <Box display="flex" justifyContent="center">
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontWeight: 700,
                  fontSize: "1.3rem",
                  lineHeight: 2,
                  color: "#FBFCFC !important",
                }}
              >
                Vardiya Saatlerine Göre
              </Typography>
            </Box>
            <Card
              sx={{
                boxShadow: "none",
                background: "transparent",
                height: 400,
                maxHeight: 600,
                px: 3,
              }}
            >
              <CardContent
                sx={{
                  p: 0,
                  width: "100%",
                  height: "100%",
                  "& .apexcharts-legend-text": {
                    color: "#ddd !important",
                  },
                  "& .apexcharts-xcrosshairs.apexcharts-active": {
                    opacity: 0,
                  },
                  "& .apexcharts-yaxis-label": {
                    fill: "#ddd !important",
                  },
                  "& .apexcharts-xaxis-label": {
                    fill: "#ddd !important",
                  },
                  "& .apexcharts-tooltip": {
                    color: "#ddd !important",
                  },
                  "& .apexcharts-tooltip-title": {
                    color: "#ddd !important",
                    backgroundColor: "#212121 !important",
                    border: 0,
                  },
                  "& .apexcharts-tooltip-box": {
                    color: "#ddd !important",
                    backgroundColor: "#212121 !important",
                  },
                  "& .apexcharts-tooltip .apexcharts-tooltip-series-group": {
                    color: "#ddd !important",
                    backgroundColor: "#212121 !important",
                  },
                }}
              >
                <ReactApexChart
                  options={options}
                  series={series}
                  type="area"
                  height={"100%"}
                />
              </CardContent>
            </Card>
            */}
          <Card
            sx={{
              boxShadow: "none",
              background: "transparent",
              px: 3,
            }}
          >
            <CardContent
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 1536,
                  background: "rgba(255, 255, 255, 0.13)",
                  borderRadius: 1.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    pl: 1,
                    pr: 2,
                    py: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: 1.5,
                    }}
                  >
                    <DateCustomizedFilterButton
                      dateRange={dateRangeFilter}
                      setDateRange={setDateRangeFilter}
                    />
                    <OperatorCustomizedFilterButton
                      operators={filterOperators}
                      setOperators={setFilterOperators}
                    />
                    <Tooltip title="Filtrelenmiş Vardiya Raporunu Görüntüle">
                      <IconButton
                        sx={{
                          position: "relative",
                          width: 45,
                          height: 45,
                          borderRadius: 1.5,
                          color: "rgb(236, 240, 241)",
                          background: "rgba(236, 240, 241, 0.12)",
                          "&:hover": {
                            border: "none",
                            background:
                              "radial-gradient(circle at top, #50ae44 , #8474ae)",
                          },
                          border: "1px solid rgba(236, 240, 241, 0.48)",
                        }}
                        onClick={onCreateVirtualShift}
                      >
                        <AutoGraphRounded />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "inherit",
                          fontWeight: 600,
                          fontSize: "0.85rem",
                          color: "#f2f3f4 !important",
                        }}
                      >
                        Toplam Çıkan Ürün
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "inherit",
                          fontWeight: 700,
                          fontSize: "1.15rem",
                          color: "#FBFCFC !important",
                        }}
                      >
                        {totalProductCount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <DataGridSpeacial
                  sortModel={[
                    {
                      field: "date",
                      sort: "desc",
                    },
                  ]}
                  columns={customizedColumns}
                  rows={rows}
                  onDetail={onDetail}
                  rowCountState={rowCountState}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              </Box>
            </CardContent>
          </Card>
          <Box display="flex" justifyContent="center">
            <Typography
              sx={{
                fontFamily: "inherit",
                fontWeight: 700,
                fontSize: "1.15rem",
                lineHeight: 2,
                color: "#FBFCFC !important",
              }}
            >
              Vardiya Çıkan Ürün Grafiği
            </Typography>
          </Box>
          <Card
            sx={{
              boxShadow: "none",
              background: "transparent",
              height: 400,
              maxHeight: 600,
              px: 3,
            }}
          >
            <CardContent
              sx={{
                p: 0,
                width: "100%",
                height: "100%",
                "& .apexcharts-legend-text": {
                  color: "#ddd !important",
                },
                "& .apexcharts-xcrosshairs.apexcharts-active": {
                  opacity: 0,
                },
                "& .apexcharts-yaxis-label": {
                  fill: "#ddd !important",
                },
                "& .apexcharts-xaxis-label": {
                  fill: "#ddd !important",
                },
                "& .apexcharts-tooltip": {
                  color: "#ddd !important",
                },
                "& .apexcharts-tooltip-title": {
                  color: "#ddd !important",
                  backgroundColor: "#212121 !important",
                  border: 0,
                },
                "& .apexcharts-tooltip-box": {
                  color: "#ddd !important",
                  backgroundColor: "#212121 !important",
                },
                "& .apexcharts-tooltip .apexcharts-tooltip-series-group": {
                  color: "#ddd !important",
                  backgroundColor: "#212121 !important",
                },
              }}
            >
              <ReactApexChart
                options={optionsByProduct}
                series={seriesByProduct}
                type="bar"
                height={"100%"}
              />
            </CardContent>
          </Card>
          <Box display="flex" justifyContent="center">
            <Typography
              sx={{
                fontFamily: "inherit",
                fontWeight: 700,
                fontSize: "1.15rem",
                lineHeight: 2,
                color: "#FBFCFC !important",
              }}
            >
              Vardiya Kullanılabilirlik ve Performans Grafiği
            </Typography>
          </Box>
          <Card
            sx={{
              boxShadow: "none",
              background: "transparent",
              height: 400,
              maxHeight: 600,
              px: 3,
            }}
          >
            <CardContent
              sx={{
                p: 0,
                width: "100%",
                height: "100%",
                "& .apexcharts-legend-text": {
                  color: "#ddd !important",
                },
                "& .apexcharts-xcrosshairs.apexcharts-active": {
                  opacity: 0,
                },
                "& .apexcharts-yaxis-label": {
                  fill: "#ddd !important",
                },
                "& .apexcharts-xaxis-label": {
                  fill: "#ddd !important",
                },
                "& .apexcharts-tooltip": {
                  color: "#ddd !important",
                },
                "& .apexcharts-tooltip-title": {
                  color: "#ddd !important",
                  backgroundColor: "#212121 !important",
                  border: 0,
                },
                "& .apexcharts-tooltip-box": {
                  color: "#ddd !important",
                  backgroundColor: "#212121 !important",
                },
                "& .apexcharts-tooltip .apexcharts-tooltip-series-group": {
                  color: "#ddd !important",
                  backgroundColor: "#212121 !important",
                },
              }}
            >
              <ReactApexChart
                options={optionsByAvailabilityAndPerformance}
                series={seriesByAvailabilityAndPerformance}
                type="bar"
                height={"100%"}
              />
            </CardContent>
          </Card>
        </Container>
        <Container maxWidth={"xl"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mb: 0,
                pt: 7,
                pb: 0.5,
              }}
            >
              {/*
             
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  lineHeight: 2,
                  color: "#f9f9f9",
                }}
                align="center"
              >
                {"Copyright © "}
                {new Date().getFullYear()} All Rights Reserved
              </Typography>
               */}
            </Box>
          </Box>
        </Container>
        <ShiftHourInfoDialog
          shiftHourData={selectedShiftHourData}
          dialogState={shiftHourInfoDialogState}
          setDialogState={setShiftHourInfoDialogState}
        />
        <ProductionFlowStateDialog
          messageData={message}
          dialogState={productionFlowStateDialogState}
          setDialogState={setProductionFlowStateDialogState}
        />
      </Box>
    </>
  );
}

export default App;

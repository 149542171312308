// ** Icons Imports
import {
  CloseRounded,
  FlagTwoTone,
  InfoOutlined,
  MoodRounded,
  ReportGmailerrorredRounded,
  ReportProblemRounded,
  SentimentDissatisfiedRounded,
  SentimentNeutralRounded,
  SentimentSatisfiedAltRounded,
} from "@mui/icons-material";

// ** MUI Imports
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { convertMsToTime } from "./MainAnalytics";
import { useEffect, useState } from "react";

import SelectedMachineSensorInfoDialog from "./SelectedMachineSensorInfoDialog";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Machines } from "../message";

// ** Types
type SelectedMachineInfoDialogProps = {
  selectedMachine: Machines[0] | null;
  setSelectedMachine: (value: React.SetStateAction<Machines[0] | null>) => void;
};

const SelectedMachineInfoDialog = (props: SelectedMachineInfoDialogProps) => {
  // ** Props
  const { selectedMachine, setSelectedMachine } = props;

  // ** States
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [machineSensorInfoDialogState, setMachineSensorInfoDialogState] =
    useState<boolean>(false);
  const [selectedSensorId, setSelectedSensorId] = useState<number>(0);

  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    if (selectedMachine) {
      setTitle(`${selectedMachine.name} - Makine Bilgileri`);
      setDialogState(true);
    }
  }, [selectedMachine]);

  const [productivityColor, setProductivityValue] =
    useState<string>("2, 224, 164");
  const [productivityEmoji, setProductivityEmoji] = useState<any>();

  const returnColor = () => {
    if (Number(selectedMachine?.Efficiency) >= 85) {
      return "2, 224, 164";
    }

    if (Number(selectedMachine?.Efficiency) >= 60) {
      return "131, 217, 20";
    }

    if (Number(selectedMachine?.Efficiency) >= 40) {
      return "243, 156, 18";
    }

    return "255, 76, 81";
  };

  useEffect(() => {
    setProductivityValue(returnColor());
    setProductivityEmoji(returnEmoji());
  }, [selectedMachine?.Efficiency]);

  const returnEmoji = () => {
    if (Number(selectedMachine?.Efficiency) >= 85) {
      return (
        <MoodRounded
          sx={{
            fontSize: 52,
          }}
        />
      );
    }

    if (Number(selectedMachine?.Efficiency) >= 60) {
      return <SentimentSatisfiedAltRounded sx={{ fontSize: 52 }} />;
    }

    if (Number(selectedMachine?.Efficiency) >= 40) {
      return (
        <SentimentNeutralRounded
          sx={{
            fontSize: 52,
          }}
        />
      );
    }

    return (
      <SentimentDissatisfiedRounded
        sx={{
          fontSize: 52,
        }}
      />
    );
  };

  return (
    <>
      <Dialog
        fullWidth
        open={dialogState}
        maxWidth="md"
        scroll="body"
        sx={{
          ".MuiDialog-paper": {
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            background: "rgba(255, 255, 255, 0.13) !important",
            backdropFilter: "blur(16px)",
          },
        }}
      >
        <DialogTitle sx={{ p: 0, mb: 5 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              p: 1,
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.09)",
                borderBottom: "3px solid rgba(255, 255, 255, 0.75)",
                p: 1,
                borderRadius: 1,
              }}
            >
              <FlagTwoTone
                sx={{
                  color: "#f0f3f4",
                }}
              />
            </Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "1.3rem",
                color: "#f0f3f4",
                fontWeight: 500,
              }}
            >
              {title}
            </Typography>
            <IconButton
              sx={{
                color: "#f0f3f4",
              }}
              onClick={() => {
                setSelectedMachine(null);
                setDialogState(false);
              }}
              size="small"
              aria-label="settings"
            >
              <CloseRounded />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 3.5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              px: 1,
              width: { xs: "100%", md: "85%" },
              mx: "auto",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                flexWrap: "wrap",
                borderRadius: 1,
                py: 1,
                px: 2,
              }}
            >
              {/*

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 5,
                  px: 3,
                  py: 2,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "rgba(255,255,255,0.72)",
                        }}
                      >
                        Kullanılabilirlik (Availability)
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: 1,
                            background: "#ffa233",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            color: "rgba(255,255,255,0.87)",
                          }}
                        >
                          {selectedMachine?.Availability &&
                            selectedMachine.Availability.toFixed(1)}{" "}
                          %
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        borderRadius: 1,
                        background: "rgba(255, 139, 0, 0.13)",
                        height: 12,
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          background: "rgb(255, 139, 0)",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: `calc(100% - ${selectedMachine?.Availability}%)`,
                          borderRadius: 1,
                        }}
                      ></Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "rgba(255,255,255,0.72)",
                        }}
                      >
                        Performans (Performance)
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: 1,
                            background: "#fdd566",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "rgba(255,255,255,0.87)",
                          }}
                        >
                          {selectedMachine?.Performance &&
                            selectedMachine.Performance.toFixed(1)}{" "}
                          %
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        borderRadius: 1,
                        background: "rgba(253, 202, 64, 0.13)",
                        height: 12,
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          background: "rgb(253, 202, 64)",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: `calc(100% - ${selectedMachine?.Performance}%)`,
                          borderRadius: 1,
                        }}
                      ></Box>
                    </Box>
                  </Box>
                  
                  
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "rgb(255,255,255,0.72)",
                        }}
                      >
                        Kalite (Quality)
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: 1,
                            background: "#508aff",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "rgb(255,255,255,0.87)",
                          }}
                        >
                          {selectedMachine?.Quality &&
                            selectedMachine.Quality.toFixed(1)}{" "}
                          %
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        borderRadius: 1,
                        background: "rgba(36, 109, 255, 0.12)",
                        height: 12,
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          background: "rgb(36, 109, 255)",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: `calc(100% - ${selectedMachine?.Quality}%)`,
                          borderRadius: 1,
                        }}
                      ></Box>
                    </Box>
                  </Box>
                </Box>
                
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 24,
                      left: 0,
                      right: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      color: `rgb(${productivityColor})`,
                    }}
                  >
                    {productivityEmoji}
                  </Box>
                  <Box
                    sx={{
                      width: 96,
                      height: 96,
                    }}
                  >
                    <CircularProgressbar
                      value={selectedMachine?.Efficiency || 0}
                      strokeWidth={9}
                      styles={buildStyles({
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: "butt",

                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,

                        // Colors
                        pathColor: `rgba(${productivityColor}, ${90 / 100})`,
                        trailColor: `rgba(${productivityColor}, 0.13)`,
                      })}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: 700,
                      color: "rgb(255,255,255,0.87)",
                    }}
                  >
                    {selectedMachine?.Efficiency &&
                      selectedMachine.Efficiency.toFixed(1)}{" "}
                    %
                  </Typography>
                </Box>
              </Box>*/}
            </Box>
            {selectedMachine?.Errors && selectedMachine.Errors.length > 0 && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  background: "rgba(255, 61, 40, 0.25)",
                  border: "1px solid rgba(255,255,255, 0.24)",
                  borderRadius: 1,
                  py: 1,
                  px: 2,
                  mt: 3,
                  mb: 1.5,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#f0f3f4",
                    }}
                  >
                    Anlık Hata
                  </Typography>
                </Box>
                {selectedMachine?.Errors.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(255, 61, 40)",
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}

            {selectedMachine?.Sensors &&
              selectedMachine.Sensors.map((item, index) => (
                <Tooltip title="Sensör Yeri Fotoğrafı Görüntüle" key={index}>
                  <div
                    className="card"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                    }}
                    onClick={() => {
                      setSelectedSensorId(item.id);
                      setMachineSensorInfoDialogState(true);
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        alignItems: "center",
                        flexWrap: "wrap",
                        pl: 1.5,
                        pr: 3,
                        py: 1,
                      }}
                    >
                      <Box
                        sx={{
                          flex: 0.58,
                          minWidth: { xs: "100%", sm: "200px" },
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-start" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "0.9rem !important",
                            color: "#FBFBFC !important",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      {item.name !== "Etiket Değişimi Yapılıyor" && (
                        <Box
                          sx={{
                            flex: 0.21,
                            display: "flex",
                            minWidth: { xs: "100%", sm: "100px" },
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "0.9rem !important",
                                color: "#D0D3D4",
                              }}
                            >
                              Tekrar Sayısı
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: 2,
                              px: 0.8,
                              py: 0.4,
                              minWidth: 85,
                              backgroundColor: "rgba(255, 56, 34, 0.13)",
                              ":hover": {
                                backgroundColor: "rgba(255, 56, 34, 0.21)",
                              },
                              border: "1px solid rgba(255, 56, 34)",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "0.9rem !important",
                                color: "rgba(255, 56, 34) !important",
                              }}
                            >
                              {item.count}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {item.time ? (
                        <Box
                          sx={{
                            flex: 0.21,
                            minWidth: { xs: "100%", sm: "100px" },
                            display: "flex",
                            justifyContent: { xs: "center", md: "flex-end" },
                            alignItems: "center",
                            flexDirection: "column",
                            gap: 0.5,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "0.9rem !important",
                                color: "#D0D3D4",
                              }}
                            >
                              Duruş Süresi
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: 2,
                              px: 0.8,
                              py: 0.4,
                              minWidth: 85,
                              backgroundColor: "rgba(255, 56, 34, 0.13)",
                              ":hover": {
                                backgroundColor: "rgba(255, 56, 34, 0.21)",
                              },
                              border: "1px solid rgba(255, 56, 34)",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "rgba(255, 56, 34) !important",
                                fontSize: "0.9rem !important",
                              }}
                            >
                              {
                                convertMsToTime(item.time as number)
                                  .digitalColock
                              }
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            flex: 0.21,
                            minWidth: { xs: "100%", sm: "100px" },
                            display: "flex",
                            justifyContent: { xs: "center", md: "flex-end" },
                            alignItems: "center",
                            flexDirection: "column",
                            gap: 0.5,
                          }}
                        ></Box>
                      )}

                      <InfoOutlined
                        sx={{
                          position: "absolute",
                          fontSize: "1.3rem",
                          color: "#f0f3f4",
                          right: 7,
                        }}
                      />
                    </Box>
                  </div>
                </Tooltip>
              ))}
            {selectedMachine?.ErrorsFromOperator &&
              selectedMachine.ErrorsFromOperator.map((item, index) => (
                <div
                  key={index}
                  className="card"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      justifyContent: { xs: "center", sm: "space-between" },
                      alignItems: "center",
                      flexWrap: "wrap",
                      pl: 1.5,
                      pr: 3,
                      py: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 0.79,
                        minWidth: { xs: "100%", sm: "200px" },
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: { xs: "center", sm: "flex-start" },
                        alignItems: {xs: "center", sm: "flex-start"},
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "0.9rem !important",
                          color: "#FBFBFC !important",
                        }}
                      >
                        {item.title}
                      </Typography>
                      {
                        item.description &&                       <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-start" },
                          alignItems: "center",
                          gap: 1,
                          py: 1,
                        }}
                      >
                        <ReportGmailerrorredRounded
                          fontSize="small"
                          sx={{
                            color: "#e5e7e9 !important",
                          }}
                        />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "0.75rem !important",
                            color: "#e5e7e9 !important",
                          }}
                        >
                          {item.description}
                        </Typography>
                      </Box>
                      }

                    </Box>
                    <Box
                      sx={{
                        flex: 0.21,
                        minWidth: { xs: "100%", sm: "100px" },
                        display: "flex",
                        justifyContent: { xs: "center", md: "flex-end" },
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 0.5,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "0.9rem !important",
                            color: "#D0D3D4",
                          }}
                        >
                          Duruş Süresi
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: 2,
                          px: 0.8,
                          py: 0.4,
                          minWidth: 85,
                          backgroundColor: "rgba(255, 56, 34, 0.13)",
                          ":hover": {
                            backgroundColor: "rgba(255, 56, 34, 0.21)",
                          },
                          border: "1px solid rgba(255, 56, 34)",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "rgba(255, 56, 34) !important",
                            fontSize: "0.9rem !important",
                          }}
                        >
                          {
                            convertMsToTime(item.duration as number)
                              .digitalColock
                          }
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              ))}
          </Box>
        </DialogContent>
      </Dialog>
      <SelectedMachineSensorInfoDialog
        selectedSensorId={selectedSensorId}
        name={selectedMachine?.name || ""}
        dialogState={machineSensorInfoDialogState}
        setDialogState={setMachineSensorInfoDialogState}
      />
    </>
  );
};

export default SelectedMachineInfoDialog;

// ** Icons Imports
import {
  CloseRounded,
  InfoTwoTone,
  SensorsRounded,
  TimerTwoTone,
  TouchAppRounded,
} from "@mui/icons-material";

// ** MUI Imports
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import DebugModeInfo from "./DebugModeInfo";
import { Line, Machines, MachineType } from "../message";

// ** Types
type LineDownInfoDialogProps = {
  lineInfoData: Line;
  machines: Machines;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

export const generateTime = (time: number | null | undefined) => {
  if (time) {
    if (time % 60 > 0) {
      return `${(time / 60).toFixed(0)} dk ${(time % 60).toFixed(0)} sn`;
    } else {
      return `${(time / 60).toFixed(0)} dk`;
    }
  }

  return "0 dk";
};

const LineDownInfoDialog = (props: LineDownInfoDialogProps) => {
  // ** Props
  const { lineInfoData, machines, dialogState, setDialogState } = props;

  // ** States
  const returnErrorColor = (machineType: MachineType | undefined) => {
    if (machineType === "GENERAL") {
      //return "#F48FB1";
      return "244, 143, 177";
    } else if (machineType === "BESLEME") {
      //return "#80CBC4";
      return "128, 203, 196";
    } else if (machineType === "DOLUM") {
      //return "#FFAB91";
      return "255, 171, 145";
    } else if (machineType === "ETIKET") {
      //return "#81D4FA";
      return "129, 212, 250";
    } else if (machineType === "KUTULAMA") {
      //return "#B39DDB";
      return "179, 157, 219";
    } else if (machineType === "ITS") {
      //return "#A5D6A7";
      return "165, 214, 167";
    } else if (machineType === "KOLILEME") {
      //return "#e85454";
      return "232, 84, 84";
    }

    //return "#f0f3f4";
    return "240, 243, 244";
  };

  // ** Functions
  const onClose = () => {
    setDialogState(false);
  };

  const sensorsErrorSum = () => {
    let sum = 0;

    machines.forEach((d) => {
      d.Sensors.forEach((item) => {
        if (item.sensor_type === "ERROR" && item.time) {
          sum = sum + Number(item.time);
        }
      });
    });

    return (sum / 60).toFixed(0);
  };

  const operatorsErrorSum = () => {
    let sum = 0;

    machines.forEach((d) => {
      d.ErrorsFromOperator?.forEach((item) => {
        sum = sum + Number(item.duration);
      });
    });

    return (sum / 60).toFixed(0);
  };

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="md"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          background: "rgba(255, 255, 255, 0.13) !important",
          backdropFilter: "blur(16px)",
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.75)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <TimerTwoTone
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
              fontWeight: 600,
            }}
          >
            Duruş Süresi Analizi
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="small"
            aria-label="settings"
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3.5, px: { xs: 1, md: 10 } }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              background: "rgb(33, 36, 45)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
              gap: 2.5,
            }}
          >
            <InfoTwoTone
              sx={{
                fontSize: "1.75rem",
                color: "#d0d3d4",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontStyle: "italic",
                  color: "#f0f3f4",
                }}
              >
                Planlı ve bilinen duruş süreleri ile sensörlerden alınan ve
                operatör girişlerinden alınan plansız duruş sürelerinin toplamı
                olan süre.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: " 1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label="LinePlannedStops" top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Planlanmış Duruşlar
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {lineInfoData.PlannedStops} dk
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: " 1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label="LinePlannedStops1" top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Temizlik ve Ayar
                </Typography>
              </Box>
              <Typography
                sx={{
                  pr: 0.5,
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.PlannedStops1} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label="LinePlannedStops2" top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Diğer
                </Typography>
              </Box>
              <Typography
                sx={{
                  pr: 0.5,
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.PlannedStops2} dk
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Planlanmamış Duruşlar
                  </Typography>
                </Box>
                <DebugModeInfo label={"LineUnplannedStops"} top={0} left={0} />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {lineInfoData.UnplannedStops &&
                    lineInfoData.UnplannedStops.toFixed(0)}{" "}
                  dk
                </Typography>
              </Box>
            </Box>
            {/*
              
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                        fontWeight: 600,
                      }}
                    >
                      Sensörden Gelen Duruşlar
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    pr: 0.5,
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {sensorsErrorSum()} dk
                </Typography>
              </Box> */}
            {machines.map((item) => (
              <>
                {item.Sensors.map((subItem, subIndex) => (
                  <>
                    {subItem.sensor_type === "ERROR" && (
                      <Box
                        key={subIndex}
                        sx={{
                          width: "calc(100% - 36px)",
                          display: "flex",
                          justifyContent: { xs: "center", sm: "space-between" },
                          alignItems: "center",
                          flexWrap: "wrap",
                          borderBottom: "1px solid rgba(255,255,255, 0.13)",
                          py: 0.5,
                          ml: 4,
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            flex: 0.66,
                            minWidth: { xs: "100%", sm: "200px" },
                            position: "relative",
                            display: "flex",
                            justifyContent: { xs: "center", sm: "flex-start" },
                            gap: 1.5,
                          }}
                        >
                          <Typography
                            sx={{
                              color: `rgb(${returnErrorColor(item.machine_type)})`,
                            }}
                          >
                            {subItem.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            flex: 0.18,
                            minWidth: { xs: "100%", sm: "128px" },
                            display: "flex",
                            justifyContent: { xs: "center", sm: "flex-start" },
                            alignItems: "center",
                            color: `rgb(${returnErrorColor(item.machine_type)})`,
                            border: `1px dashed rgba(${returnErrorColor(item.machine_type)}, 0.81)`,
                            background: `rgba(${returnErrorColor(item.machine_type)}, 0.21)`,
                            borderRadius: 1,
                            px: 1,
                            py: 0.5,
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <SensorsRounded
                              sx={{
                                fontSize: "1.1rem",
                                color: `rgb(${returnErrorColor(item.machine_type)})`,
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography>{item.machine_type}</Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            flex: 0.16,
                            minWidth: { xs: "100%", sm: "100px" },
                            display: "flex",
                            gap: 1,
                            justifyContent: { xs: "center", sm: "flex-end" },
                          }}
                        >
                          <Typography
                            sx={{
                              color: `rgb(${returnErrorColor(item.machine_type)})`,
                              mr: 0.5,
                            }}
                          >
                            {generateTime(subItem.time)}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </>
                ))}
              </>
            ))}
            {/*
              
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                  py: 0.5,
                  ml: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row-reverse",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                        fontWeight: 600,
                      }}
                    >
                      Operatörden Gelen Duruşlar
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    pr: 0.5,
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {operatorsErrorSum()} dk
                </Typography>
              </Box> */}
            {lineInfoData.ErrorsFromOperator?.map((subItem, subIndex) => (
              <Box
                key={subIndex}
                sx={{
                  width: "calc(100% - 36px)",
                  display: "flex",
                  flexDirection: "column",
                  py: 0.5,
                  ml: 4,
                  gap: 0.5,
                  borderBottom: "1px solid rgba(255,255,255, 0.13)",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: { xs: "center", sm: "space-between" },
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      flex: 0.66,
                      minWidth: { xs: "100%", sm: "200px" },
                      position: "relative",
                      display: "flex",
                      justifyContent: { xs: "center", sm: "flex-start" },
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                      }}
                    >
                      {subItem.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 0.18,
                      minWidth: { xs: "100%", sm: "128px" },
                      display: "flex",
                      justifyContent: { xs: "center", sm: "flex-start" },
                      alignItems: "center",
                      color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                      border: `1px dashed rgba(${returnErrorColor(MachineType.GENERAL)}, 0.81)`,
                      background: `rgba(${returnErrorColor(MachineType.GENERAL)}, 0.21)`,
                      borderRadius: 1,
                      px: 1,
                      py: 0.5,
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TouchAppRounded
                        sx={{
                          fontSize: "1.1rem",
                          color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography>GENEL</Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flex: 0.16,
                      minWidth: { xs: "100%", sm: "100px" },
                      display: "flex",
                      justifyContent: { xs: "center", sm: "flex-end" },
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: `rgb(${returnErrorColor(MachineType.GENERAL)})`,
                        mr: 0.5,
                      }}
                    >
                      {generateTime(subItem.duration)}
                    </Typography>
                  </Box>
                </Box>
                {subItem.description && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <InfoTwoTone
                      sx={{
                        fontSize: "1rem",
                        color: "#d0d3d4",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#f0f3f4",
                        fontSize: "0.81rem",
                      }}
                    >
                      {subItem.description}
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
            {machines.map((item) => (
              <>
                {item.ErrorsFromOperator?.map((subItem, subIndex) => (
                  <Box
                    key={subIndex}
                    sx={{
                      width: "calc(100% - 36px)",
                      display: "flex",
                      flexDirection: "column",
                      py: 0.5,
                      ml: 4,
                      gap: 0.5,
                      borderBottom: "1px solid rgba(255,255,255, 0.13)",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          flex: 0.66,
                          minWidth: { xs: "100%", sm: "200px" },
                          position: "relative",
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-start" },
                          gap: 1.5,
                        }}
                      >
                        <Typography
                          sx={{
                            color: `rgb(${returnErrorColor(item.machine_type)})`,
                          }}
                        >
                          {subItem.title}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          flex: 0.18,
                          minWidth: { xs: "100%", sm: "128px" },
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-start" },
                          alignItems: "center",
                          color: `rgb(${returnErrorColor(item.machine_type)})`,
                          border: `1px dashed rgba(${returnErrorColor(item.machine_type)}, 0.81)`,
                          background: `rgba(${returnErrorColor(item.machine_type)}, 0.21)`,
                          borderRadius: 1,
                          px: 1,
                          py: 0.5,
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TouchAppRounded
                            sx={{
                              fontSize: "1.1rem",
                              color: `rgb(${returnErrorColor(item.machine_type)})`,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography>{item.machine_type}</Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          flex: 0.16,
                          minWidth: { xs: "100%", sm: "100px" },
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-end" },
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: `rgb(${returnErrorColor(item.machine_type)})`,
                            mr: 0.5,
                          }}
                        >
                          {generateTime(subItem.duration)}
                        </Typography>
                      </Box>
                    </Box>
                    {subItem.description && (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <InfoTwoTone
                          sx={{
                            fontSize: "1rem",
                            color: "#d0d3d4",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#f0f3f4",
                            fontSize: "0.81rem",
                          }}
                        >
                          {subItem.description}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ))}
              </>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                color: "#f0f3f4",
                fontWeight: 700,
                fontSize: "1.15rem",
              }}
            >
              Hesaplamalar
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(211deg 29% 25%) 0%, hsl(222deg 33% 18%) 59%, hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label={"LineDownTime"} top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#AF7AC5",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    Durma Süresi
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#AF7AC5",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>
                  <Typography
                    sx={{
                      color: "#efe4f3",
                      fontStyle: "italic",
                    }}
                  >
                    Planlanmış Duruşlar + Planlanmamış Duruşlar
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "#AF7AC5",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                {lineInfoData.DownTime &&
                  (lineInfoData.DownTime / 60).toFixed(0)}{" "}
                dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LinePlannedStops"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanmış Duruşlar
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.PlannedStops} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineUnplannedStops"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanmamış Duruşlar
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.UnplannedStops &&
                  lineInfoData.UnplannedStops.toFixed(0)}{" "}
                dk
              </Typography>
            </Box>
            {/*
            
            <Box
              sx={{
                width: "calc(100% - 48px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 6,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineUnplannedStops"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Sensörden Gelen Duruşlar
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.UnplannedStops &&
                  lineInfoData.UnplannedStops.toFixed(0)}{" "}
                dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 48px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 6,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LinePlannedStops"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Operatörden Gelen Duruşlar
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.PlannedStops} dk
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LineDownInfoDialog;

/* eslint-disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type Id = number;
export type Name = string;
export type Green = boolean;
export type Yellow = boolean;
export type Red = boolean;
/**
 * Çalışma Süresi
 */
export type Runtime = number;
/**
 * Duruş Süresi
 */
export type Downtime = number;
/**
 * Mola Süresi
 */
export type Warningtime = number;
export type Idletime = number;
/**
 * Verim
 */
export type Efficiency = number;
/**
 * Kullanılabilirlik
 */
export type Availability = number;
/**
 * Performans
 */
export type Performance = number;
/**
 * Kalite
 */
export type Quality = number;
export type Title = string;
export type Description = string;
export type Duration = number;
export type CreatedAt = string;
export type StartedAt = string;
export type Errorsfromoperator = Error[];
export type Id1 = number;
export type Name1 = string;
export type Count = number;
export type Time = number | null;
export type Sensors = Sensor[];
export type Errors = string[];
export type Id2 = number;
export type Name2 = string;
export type Green1 = boolean;
export type Yellow1 = boolean;
export type Red1 = boolean;
/**
 * Çalışma Süresi
 */
export type Runtime1 = number;
/**
 * Duruş Süresi
 */
export type Downtime1 = number;
/**
 * Mola Süresi
 */
export type Warningtime1 = number;
export type Idletime1 = number;
/**
 * Verim
 */
export type Efficiency1 = number;
/**
 * Kullanılabilirlik
 */
export type Availability1 = number;
/**
 * Performans
 */
export type Performance1 = number;
/**
 * Kalite
 */
export type Quality1 = number;
export type Errorsfromoperator1 = Error[];
export type Sensors1 = Sensor[];
export type Errors1 = string[];
export type Id3 = number;
export type Name3 = string;
export type Green2 = boolean;
export type Yellow2 = boolean;
export type Red2 = boolean;
/**
 * Çalışma Süresi
 */
export type Runtime2 = number;
/**
 * Duruş Süresi
 */
export type Downtime2 = number;
/**
 * Mola Süresi
 */
export type Warningtime2 = number;
export type Idletime2 = number;
/**
 * Verim
 */
export type Efficiency2 = number;
/**
 * Kullanılabilirlik
 */
export type Availability2 = number;
/**
 * Performans
 */
export type Performance2 = number;
/**
 * Kalite
 */
export type Quality2 = number;
export type Errorsfromoperator2 = Error[];
export type Sensors2 = Sensor[];
export type Errors2 = string[];
export type Id4 = number;
export type Name4 = string;
export type Green3 = boolean;
export type Yellow3 = boolean;
export type Red3 = boolean;
/**
 * Çalışma Süresi
 */
export type Runtime3 = number;
/**
 * Duruş Süresi
 */
export type Downtime3 = number;
/**
 * Mola Süresi
 */
export type Warningtime3 = number;
export type Idletime3 = number;
/**
 * Verim
 */
export type Efficiency3 = number;
/**
 * Kullanılabilirlik
 */
export type Availability3 = number;
/**
 * Performans
 */
export type Performance3 = number;
/**
 * Kalite
 */
export type Quality3 = number;
export type Errorsfromoperator3 = Error[];
export type Sensors3 = Sensor[];
export type Errors3 = string[];
export type Id5 = number;
export type Name5 = string;
export type Green4 = boolean;
export type Yellow4 = boolean;
export type Red4 = boolean;
/**
 * Çalışma Süresi
 */
export type Runtime4 = number;
/**
 * Duruş Süresi
 */
export type Downtime4 = number;
/**
 * Mola Süresi
 */
export type Warningtime4 = number;
export type Idletime4 = number;
/**
 * Verim
 */
export type Efficiency4 = number;
/**
 * Kullanılabilirlik
 */
export type Availability4 = number;
/**
 * Performans
 */
export type Performance4 = number;
/**
 * Kalite
 */
export type Quality4 = number;
export type Errorsfromoperator4 = Error[];
export type Sensors4 = Sensor[];
export type Errors4 = string[];
export type Id6 = number;
export type Name6 = string;
export type Green5 = boolean;
export type Yellow5 = boolean;
export type Red5 = boolean;
/**
 * Çalışma Süresi
 */
export type Runtime5 = number;
/**
 * Duruş Süresi
 */
export type Downtime5 = number;
/**
 * Mola Süresi
 */
export type Warningtime5 = number;
export type Idletime5 = number;
/**
 * Verim
 */
export type Efficiency5 = number;
/**
 * Kullanılabilirlik
 */
export type Availability5 = number;
/**
 * Performans
 */
export type Performance5 = number;
/**
 * Kalite
 */
export type Quality5 = number;
export type Errorsfromoperator5 = Error[];
export type Sensors5 = Sensor[];
export type Errors5 = string[];
export type Machines = (Besleme | Dolum | Etiket | Kutulama | ITS | Kolileme)[];
export type Green6 = boolean;
export type Yellow6 = boolean;
export type Red6 = boolean;
/**
 * Duruş Süresi
 */
export type Downtime6 = number;
/**
 * Çalışma Süresi
 */
export type Workingtime = number;
/**
 * Mola Süresi
 */
export type Breakstime = number;
/**
 * Vardiya Süresi
 */
export type Presenttime = number;
/**
 * Hat Hızı
 */
export type Idealrunrateppm = number;
/**
 * Giren Şişe
 */
export type Inbottlepieces = number;
/**
 * Dolan Şişe
 */
export type Dolumbottleoutcounter = number;
/**
 * Dolum Fire
 */
export type Unfillablebottlepieces = number;
/**
 * Etiket Fire
 */
export type Rejectbottletotalpieces = number;
/**
 * Etiketli Ürün
 */
export type Verifiedbottletotalpieces = number;
/**
 * Kutulama Fire
 */
export type Kutulamaboxrejectcounter = number;
/**
 * Çıkan Koli
 */
export type Packageoutpieces = number;
/**
 * Kutulama Tekrar
 */
export type Recyclebottles = number;
/**
 * İyi Sayım = Toplam Üretilen Adet - Reddedilen Adet
 */
export type Goodcount = number;
/**
 * Toplam Fire
 */
export type Rejecttotalpieces = number;
/**
 * Kutulanan Ürün
 */
export type Verifiedboxtotalpieces = number;
/**
 * Tabla Dolu
 */
export type Dolumturntablefull = boolean;
/**
 * Performans
 */
export type Performance6 = number;
/**
 * Kullanılabilirlik
 */
export type Availability6 = number;
/**
 * Kalite
 */
export type Quality6 = number;
/**
 * Verim
 */
export type Oee = number;
/**
 * Planlanan Üretim Süresi
 */
export type Plannedproductiontime = number;
/**
 * Toplam Planlı Duruşlar
 */
export type Plannedstops = number;
/**
 * Temizlik ve Ayar
 */
export type Plannedstops1 = number;
/**
 * Diğer
 */
export type Plannedstops2 = number;
/**
 * Planlanmamış Duruşlar
 */
export type Unplannedstops = number;
/**
 * Molalar
 */
export type Breaks = number;
/**
 * Çay Akşam FM Mola
 */
export type Breaks1 = number;
/**
 * Yemek Mola
 */
export type Breaks2 = number;
/**
 * Vardiya Uzunluğu
 */
export type Shiftlength = number;
/**
 * Vardiya Durumu
 */
export type Startproduction = boolean;
/**
 * Mola Durumu
 */
export type Startbreaks = boolean;
/**
 * Planlanmış Duruş Durumu
 */
export type Startscheduledstops = boolean;
/**
 * Planlanmış Duruş Açıklama
 */
export type Scheduledstopsdescription = string;
/**
 * Planlanmış Duruş Başlangıç Zamanı
 */
export type Scheduledstopstime = number;
/**
 * Personel Hatası
 */
export type Personelerrortitle = string;
/**
 * Personel Hatası Açıklama
 */
export type Personelerrordescription = string;
/**
 * Personel Hatası Tipi
 */
export type Personelerrormachinetype = string;
/**
 * Personel Hatası Durumu
 */
export type Personelerrorstatus = boolean;
/**
 * Personel Hata Başlangıç Zamanı
 */
export type Personelerrortime = number;
export type Errorsfromoperator6 = Error[];
export type Time1 = number;
export type Connected = boolean;
export type Text = string | null;
export type Id7 = string | null;
export type OperatorName = string | null;
export type StartedAt1 = number | null;
export type FinishedAt = number | null;

export interface Message {
  Machines: Machines;
  Line: Line;
  Time: Time1;
  Connected: Connected;
  Text?: Text;
  id?: Id7;
  operator_name?: OperatorName;
  started_at?: StartedAt1;
  finished_at?: FinishedAt;
}
/**
 * Besleme Machine Model Class
 */
export interface Besleme {
  id: Id;
  name: Name;
  machine_type?: MachineType & string;
  Green: Green;
  Yellow: Yellow;
  Red: Red;
  RunTime: Runtime;
  DownTime: Downtime;
  WarningTime: Warningtime;
  IdleTime: Idletime;
  Efficiency: Efficiency;
  Availability: Availability;
  Performance: Performance;
  Quality: Quality;
  ErrorsFromOperator?: Errorsfromoperator;
  Sensors: Sensors;
  Errors: Errors;
}
export interface Error {
  title: Title;
  description: Description;
  machine_type: MachineType;
  duration: Duration;
  created_at?: CreatedAt;
  started_at?: StartedAt;
}
export interface Sensor {
  id: Id1;
  name: Name1;
  count: Count;
  sensor_type: SensorType;
  time?: Time;
}
export interface Dolum {
  id: Id2;
  name: Name2;
  machine_type?: MachineType & string;
  Green: Green1;
  Yellow: Yellow1;
  Red: Red1;
  RunTime: Runtime1;
  DownTime: Downtime1;
  WarningTime: Warningtime1;
  IdleTime: Idletime1;
  Efficiency: Efficiency1;
  Availability: Availability1;
  Performance: Performance1;
  Quality: Quality1;
  ErrorsFromOperator?: Errorsfromoperator1;
  Sensors: Sensors1;
  Errors: Errors1;
}
export interface Etiket {
  id: Id3;
  name: Name3;
  machine_type?: MachineType & string;
  Green: Green2;
  Yellow: Yellow2;
  Red: Red2;
  RunTime: Runtime2;
  DownTime: Downtime2;
  WarningTime: Warningtime2;
  IdleTime: Idletime2;
  Efficiency: Efficiency2;
  Availability: Availability2;
  Performance: Performance2;
  Quality: Quality2;
  ErrorsFromOperator?: Errorsfromoperator2;
  Sensors: Sensors2;
  Errors: Errors2;
}
export interface Kutulama {
  id: Id4;
  name: Name4;
  machine_type?: MachineType & string;
  Green: Green3;
  Yellow: Yellow3;
  Red: Red3;
  RunTime: Runtime3;
  DownTime: Downtime3;
  WarningTime: Warningtime3;
  IdleTime: Idletime3;
  Efficiency: Efficiency3;
  Availability: Availability3;
  Performance: Performance3;
  Quality: Quality3;
  ErrorsFromOperator?: Errorsfromoperator3;
  Sensors: Sensors3;
  Errors: Errors3;
}
export interface ITS {
  id: Id5;
  name: Name5;
  machine_type?: MachineType & string;
  Green: Green4;
  Yellow: Yellow4;
  Red: Red4;
  RunTime: Runtime4;
  DownTime: Downtime4;
  WarningTime: Warningtime4;
  IdleTime: Idletime4;
  Efficiency: Efficiency4;
  Availability: Availability4;
  Performance: Performance4;
  Quality: Quality4;
  ErrorsFromOperator?: Errorsfromoperator4;
  Sensors: Sensors4;
  Errors: Errors4;
}
export interface Kolileme {
  id: Id6;
  name: Name6;
  machine_type?: MachineType & string;
  Green: Green5;
  Yellow: Yellow5;
  Red: Red5;
  RunTime: Runtime5;
  DownTime: Downtime5;
  WarningTime: Warningtime5;
  IdleTime: Idletime5;
  Efficiency: Efficiency5;
  Availability: Availability5;
  Performance: Performance5;
  Quality: Quality5;
  ErrorsFromOperator?: Errorsfromoperator5;
  Sensors: Sensors5;
  Errors: Errors5;
}
export interface Line {
  Green: Green6;
  Yellow: Yellow6;
  Red: Red6;
  DownTime: Downtime6;
  WorkingTime: Workingtime;
  BreaksTime: Breakstime;
  PresentTime: Presenttime;
  IdealRunRatePPM: Idealrunrateppm;
  InBottlePieces: Inbottlepieces;
  DolumBottleOutCounter: Dolumbottleoutcounter;
  UnfillableBottlePieces: Unfillablebottlepieces;
  RejectBottleTotalPieces: Rejectbottletotalpieces;
  VerifiedBottleTotalPieces: Verifiedbottletotalpieces;
  KutulamaBoxRejectCounter: Kutulamaboxrejectcounter;
  PackageOutPieces: Packageoutpieces;
  RecycleBottles: Recyclebottles;
  GoodCount: Goodcount;
  RejectTotalPieces: Rejecttotalpieces;
  VerifiedBoxTotalPieces: Verifiedboxtotalpieces;
  DolumTurnTableFull: Dolumturntablefull;
  Performance: Performance6;
  Availability: Availability6;
  Quality: Quality6;
  OEE: Oee;
  PlannedProductionTime: Plannedproductiontime;
  PlannedStops: Plannedstops;
  PlannedStops1: Plannedstops1;
  PlannedStops2: Plannedstops2;
  UnplannedStops: Unplannedstops;
  Breaks: Breaks;
  Breaks1: Breaks1;
  Breaks2: Breaks2;
  ShiftLength: Shiftlength;
  StartProduction: Startproduction;
  StartBreaks: Startbreaks;
  StartScheduledStops: Startscheduledstops;
  /**
   * Planlanmış Duruş Tipi
   */
  ScheduledStopsStatus?: ScheduledStatus & string;
  ScheduledStopsDescription?: Scheduledstopsdescription;
  ScheduledStopsTime?: Scheduledstopstime;
  PersonelErrorTitle?: Personelerrortitle;
  PersonelErrorDescription?: Personelerrordescription;
  PersonelErrorMachineType?: Personelerrormachinetype;
  PersonelErrorStatus?: Personelerrorstatus;
  PersonelErrorTime?: Personelerrortime;
  ErrorsFromOperator?: Errorsfromoperator6;
}

export const enum MachineType {
  BESLEME = "BESLEME",
  DOLUM = "DOLUM",
  ETIKET = "ETIKET",
  ITS = "ITS",
  KUTULAMA = "KUTULAMA",
  KOLILEME = "KOLILEME",
  GENERAL = "GENERAL",
  UNKNOWN = "UNKNOWN"
}
export const enum SensorType {
  COUNT = "COUNT",
  WARNING = "WARNING",
  ERROR = "ERROR"
}
export const enum ScheduledStatus {
  TEMIZLIK = "TEMIZLIK",
  AYAR = "AYAR",
  BAKIM = "BAKIM",
  SERIDEGISIMI = "SERIDEGISIMI"
}

// ** Icons Imports
import {
  CleaningServicesRounded,
  CloseRounded,
  ConstructionRounded,
  DoneRounded,
  FlagTwoTone,
  RotateLeftRounded,
  SettingsSuggestRounded,
} from "@mui/icons-material";
import MuiToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";

// ** MUI Imports
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  TextField,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { CleaningSettingsMaintenanceSeriesChangeInfoChange } from "../pages/InputsPage";
import axios from "axios";
import { ScheduledStatus } from "../message";

type CleaningSettingsMaintenanceSeriesChangeFirstDialogProps = {
  accessToken: string;
  cleaningSettingsMaintenanceSeriesChangeInfo: CleaningSettingsMaintenanceSeriesChangeInfoChange;
  setCleaningSettingsMaintenanceSeriesChangeInfo: (value: React.SetStateAction<CleaningSettingsMaintenanceSeriesChangeInfoChange>) => void;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    background: "rgba(0, 0, 0, 0.24)",
    color: "#f0f3f4",
    fontSize: "1.5rem",
    "& fieldset": {
      borderWidth: "1px",
      borderColor: "rgba(255, 255, 255, 0.24)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255, 255, 255, 0.48)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(255, 255, 255, 0.52)",
    },
  },
});

// Styled ToggleButton component
const ToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(
  ({ theme }) => ({
    border: "none !important",
    textTransform: "capitalize",
    fontWeight: 700,
    color: "#f0f3f4",
    "&.Mui-selected, &.Mui-selected:hover": {
      backdropFilter: "blur(16px)",
      color: "#f0f3f4",
      backgroundColor: "rgba(255, 255, 255, 0.13)",
    },
    "&:not(:first-of-type)": {
      borderRadius: `13px !important`,
    },
    "&:first-of-type": {
      borderRadius: `13px !important`,
    },
  })
);

const API_URL = process.env.REACT_APP_API_HOST || "";

const CleaningSettingsMaintenanceSeriesChangeFirstDialog = (props: CleaningSettingsMaintenanceSeriesChangeFirstDialogProps) => {
  // ** Props
  const { cleaningSettingsMaintenanceSeriesChangeInfo, setCleaningSettingsMaintenanceSeriesChangeInfo, dialogState, setDialogState, accessToken } =
    props;

  // ** States
  const [value, setValue] = useState<ScheduledStatus | null>(null);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [scheduledStatusDescription, setScheduledStatusDescription] = useState<string>("");

  const handleTypeChange = (
    event: MouseEvent<HTMLElement>,
    pValue: ScheduledStatus | null
  ) => {
    setValue(pValue);
  };

  // ** Functions
  const onClose = () => {
    setDialogState(false);
  };

  // **
  const onSaveErrorInfo = () => {
    if(!cleaningSettingsMaintenanceSeriesChangeInfo.isStarted) {
      if (value) {
        axios
          .post(
            API_URL + "/setStartScheduledStops",
            {
              val: true,
              status: value,
              description: scheduledStatusDescription,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-token": accessToken,
              },
            }
          )
          .then(() => {
            setScheduledStatusDescription("");
            setCleaningSettingsMaintenanceSeriesChangeInfo({
              ...cleaningSettingsMaintenanceSeriesChangeInfo,
              isStarted: true,
            });
            setSubmitError(false)
            setDialogState(false);
          });
      } else {
        setSubmitError(true)
      }
    } else {
      axios
      .post(
        API_URL + "/setStartScheduledStops",
        {
          val: false,
          status: value ? value : "AYAR",
          description: scheduledStatusDescription,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-token": accessToken,
          },
        }
      )
      .then(() => {
        setScheduledStatusDescription("");
        setCleaningSettingsMaintenanceSeriesChangeInfo({
          type: null,
          description: "",
          isStarted: false,
        });
        setValue(null);
        setSubmitError(false);
        setDialogState(false);
      });
    }
  };

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="xl"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          backgroundColor: "rgba(255, 255, 255, 0.13) !important",
        },
      }}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.72)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <FlagTwoTone
              fontSize="large"
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "calc(1rem + 1vh)",
              color: "#f0f3f4",
              fontWeight: 500,
            }}
          >
            {`Temizlik, Ayar, Bakım veya Seri Değişimi ${cleaningSettingsMaintenanceSeriesChangeInfo.isStarted ? "Bitir" : "Başlat"}`}
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="large"
            aria-label="settings"
          >
            <CloseRounded fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ m: 0, p: 3.5, background: "rgba(0,0,0, 0.42)" }}>
        <Box>
          <Box
            sx={{
              width: "100%",
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "calc(0.85rem + 1vh)",
                color: "#f0f3f4",
              }}
            >
              {cleaningSettingsMaintenanceSeriesChangeInfo.isStarted ? "Planlı duruşu bitirmek için onay gerekiyor" : "Planlı duruş tip seçimi ve onay gerekiyor"}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: cleaningSettingsMaintenanceSeriesChangeInfo.isStarted ? "none" : "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Box
              sx={{
                background: "rgba(0,0,0, 0.33)",
                px: 3,
                py: 2,
                borderRadius: 3,
              }}
            >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "1.45rem",
                        color: submitError ? "#e74c3c" : "#f0f3f4",
                      }}
                    >
                      Planlı Duruş Tip Seçimi
                    </Typography>
                  </Box>
              <ToggleButtonGroup
                exclusive
                value={value}
                onChange={handleTypeChange}
                aria-label="type-button-toggle"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                <ToggleButton
                  value={"TEMIZLIK"}
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                        }}
                      >
                        <CleaningServicesRounded
                          sx={{
                            fontSize: "calc(1.1rem + 6.1vh)",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        TEMİZLİK
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton
                  value={"AYAR"}
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                        }}
                      >
                        <SettingsSuggestRounded
                          sx={{
                            fontSize: "calc(1.1rem + 6.1vh)",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        AYAR
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton
                  value={"BAKIM"}
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <ConstructionRounded
                        sx={{
                          fontSize: "calc(1.1rem + 6.1vh)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        BAKIM
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    marginLeft: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton
                  value={"SERIDEGISIMI"}
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <RotateLeftRounded
                        sx={{
                          fontSize: "calc(1.1rem + 6.1vh)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        SERİ DEĞİŞİMİ
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
              <Box
                sx={{
                  mt: 3,
                }}
              >
                <CssTextField
                  placeholder="Açıklama (Opsiyonel)"
                  label="Açıklama"
                  name="description"
                  variant="outlined"
                  value={scheduledStatusDescription}
                  onChange={(e) => {
                    setScheduledStatusDescription(e.target.value);
                  }}
                  fullWidth
                  multiline
                  rows={2}
                  InputLabelProps={{
                    style: { color: "#f3f4f5", fontSize: "1.45rem" },
                  }}
                  sx={{
                    mb: 1,
                    color: "#f3f4f5",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 3,
              mt: 3,
              maxWidth: "78%",
              mx: "auto",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                flex: 1,
                color: "#f0f3f4",
                fontSize: "1.5rem",
                height: 72,
                borderColor: "rgba(255, 255, 255, 0.36)",
              }}
              onClick={onClose}
            >
              İptal
            </Button>
            <Button
              sx={{
                flex: 1,
                fontSize: "1.5rem",
                color: "#212121",
                height: 72,
                background: "rgba(255, 255, 255, 0.67)",
              }}
              variant="contained"
              onClick={onSaveErrorInfo}
              startIcon={
                <DoneRounded
                  sx={{
                    fontSize: "2.5rem !important",
                  }}
                />
              }
            >
              Onayla
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CleaningSettingsMaintenanceSeriesChangeFirstDialog;
